/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import Panel from '../../components/Panel';
import { 
  Card, 
  CardContent,
  Container,
  Typography,
  Button,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { colors } from '../../customTheme';
import { HoverRating } from '../../components/HoverRating';
import Dashboard from '../dashboard/Dashboard.tsx';
import { Experimental_CssVarsProvider } from '@mui/material/styles';
import { Analytics } from 'monolib';
import { 
  generateClosetReport,
  getClosetReports,
} from "../../api/client";
import { getUsernameFromUrl } from '../../utils/helpers';

const ClosetReport = ({ title, subtitle, description, href }) => {
  const [error, setError] = useState(null);
  const [sucess, setSucess] = useState(false);
  const [closetURL, setClosetURL] = useState('https://poshmark.ca/closet/tinaj647');
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);

  const handleGenerateReport = async () => {
    if (loading) {
      return;
    }
    if (!closetURL) {
      setError("Enter a valid closet URL to continue");
      return;
    }
    if (!closetURL.includes("posh.mk") && !closetURL.includes("poshmark.ca") && !closetURL.includes("poshmark.com")) {
      setError(`${closetURL} is not a valid closet URL`);
      return;
    }  

    setLoading(true);
    Analytics.instance.track("generate_closet_report");
    const username = getUsernameFromUrl(closetURL);
    const hostname = new URL(closetURL).hostname;
    const res = await generateClosetReport({ username, hostname });
    console.log("res", res);
    if(res?.response?.status === 404 || res?.response?.status === 500){
      setError(res.message);
    } else {
      setReports([]);
      setSucess(true);
      setLoading(false);
    }
  };

  const renderHeading = () => {
    return (
      <Card variant="outlined">
        <CardContent style={{ display: 'flex', flexDirection: 'column',  margin: '20px'}}>
          <Typography mb={2} variant='h1' style={{ fontSize: "3rem", fontFamily: "Libre Baskerville"}} >{title}</Typography>
          <Typography mb={4} variant='body1' color="textSecondary">{subtitle}</Typography>
          <Box display="flex" flexDirection='row' alignItems='center'>
            <Button 
              color="primary" 
              variant='contained' 
              onClick={() => handleGenerateReport()}
              style={{
                borderRadius: 10,
                marginRight: "10px",
                height: 44,
                width: 220,
                marginLeft: 10,
                marginTop: 0,
                boxShadow: 'none',
                backgroundColor: colors.mineShaft,
              }}>
              Generate Report
            </Button>
          </Box>
        </CardContent>
      </Card>
    )
  }

  useEffect(() => {
    const getReports = async () => {
      console.log("getReports", closetURL);
      try {
        const username = getUsernameFromUrl(closetURL);
        const hostname = new URL(closetURL).hostname;
        setLoading(true);

        const { reports } = await getClosetReports({ username, hostname });
        console.log("reports", reports);
        setReports(reports);
        setLoading(false);
      } catch (e) {
        console.error("err", e);
        setError(e.message);
        setLoading(false);
      }
    };

    getReports();
  }, [closetURL]);

  const renderResultsTable = () => {
    return(
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Rank</TableCell>
              <TableCell>Score</TableCell>
            </TableRow>
          </TableHead>
          { reports.length > 1 && 
            <TableBody>
              {reports.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.img_url}</TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell><HoverRating value={item?.score?.rating} label={item?.score?.word_rating}/></TableCell>
                  <TableCell>{item?.score?.sentence_rating}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        </Table>
      </TableContainer>
    )
  }

  return (
    <Dashboard>
      <Helmet bodyAttributes={{ style: `background-color : ${colors.alabaster}` }}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={href} />
      </Helmet>
      <Experimental_CssVarsProvider>
        <Panel>
          <Container maxWidth={false} sx={{ pt: 4 }}>
            {renderHeading()} 
            <Box
              sx={{
                backgroundColor: 'white',
                paddingTop: 4, 
                paddingLeft: 4,
                marginTop: 6,
                minHeight: '200px'
              }}
            >
              <Typography variant="h4" style={{ fontFamily: "Libre Baskerville", marginTop: 6, marginBottom: 2 }}>Reports</Typography>
              <Box sx={{ minHeight: '120px', marginTop: 4, paddingRight: 4 }}>
                { renderResultsTable() }
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ marginTop: 4, paddingBottom: 6, paddingRight: 4 }}>
                {loading && <CircularProgress />}
              </Box>
            </Box>
          </Container>
        </Panel >
      </Experimental_CssVarsProvider>
    </Dashboard >
  );
};

export default ClosetReport;
