import { useState, useMemo } from 'react';

function useFeeCalculator() {
  const [cost, setCost] = useState(0.0);
  const [salePrice, setSalePrice] = useState(0.0);

  const salePriceThreshold = 15.0;
  const sellerFeePercentage = 0.2;
  const fixedFee = 2.95;

  const emptyState = useMemo(() => {
    return cost === 0 && salePrice === 0;
  }, [cost, salePrice]);

  const sellerFee = useMemo(() => {
    if (salePrice <= salePriceThreshold) {
      return fixedFee;
    } else {
      return salePrice * sellerFeePercentage;
    }
  }, [salePrice]);

  const grossProfit = useMemo(() => {
    return salePrice - cost - sellerFee;
  }, [cost, salePrice, sellerFee]);

  const profitMargin = useMemo(() => {
    return grossProfit / salePrice * 100;
  }, [grossProfit, salePrice]);

  return {
    emptyState,
    salePriceThreshold,
    sellerFeePercentage,
    fixedFee,
    sellerFee,
    cost,
    setCost,
    salePrice,
    setSalePrice,
    grossProfit,
    profitMargin,
  };
}

export default useFeeCalculator;
