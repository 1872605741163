import { useMemo } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { useDetails } from './useDetails.ts';

const PREVIEW_ROWS = 3;

const obfuscateData = (item, columns) => {
  const obfuscatedItem = { ...item };
  columns.forEach(col => {
    obfuscatedItem[col.key] = '****';
  });
  return obfuscatedItem;
};

const limitDataForUnsignedUsers = (items, columns) => {
  if (!items) return [];
  return items.map((item, index) => 
    index < PREVIEW_ROWS ? item : obfuscateData(item, columns)
  );
};

const obfuscateTableData = (table, signedIn) => {
  if (!table || signedIn) return table;
  
  return {
    ...table,
    items: limitDataForUnsignedUsers(table.items, table.columns)
  };
};

export const useObfuscatedDetails = (props) => {
  const { signedIn } = useAuth();
  const details = useDetails(props);

  const byCategory = useMemo(() => {
    if (!details.byCategory) return null;
    
    return details.byCategory.map(table => obfuscateTableData(table, signedIn));
  }, [details.byCategory, signedIn]);

  const byBrand = useMemo(() => {
    if (!details.byBrand) return null;
    
    return details.byBrand.map(table => obfuscateTableData(table, signedIn));
  }, [details.byBrand, signedIn]);

  const progressChartData = useMemo(() => {
    if (!details.progressChartData || signedIn) return details.progressChartData;

    const { data, ...rest } = details.progressChartData;
    return {
      ...rest,
      data: data.map((item, index) => {
        if (index < PREVIEW_ROWS) return item;
        return {
          name: '****',
          amount: item.amount,
          href: '#',
          picture_url: null
        };
      })
    };
  }, [details.progressChartData, signedIn]);

  return {
    ...details,
    byCategory,
    byBrand,
    progressChartData,
  };
};