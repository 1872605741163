import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Alert from '@mui/joy/Alert';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import Sidebar from './components/Sidebar.tsx';
import PATHS from '../../utils/paths';
import CustomBreadcrumbs from './components/CustomBreadcrumbs.tsx';
import { useAuth } from '../../contexts/AuthContext';
import NewsletterPanelV2 from '../../components/newsletter-panel/NewsletterPanelV2.tsx';
import { colors } from '../../customTheme.js';
import Intercom from '@intercom/messenger-js-sdk';
import Header from './components/Header.tsx';

interface DashboardProps {
  children?: React.ReactNode;
}

export default function Dashboard(props: DashboardProps) {
  const { children } = props;
  const [showBanner, setShowBanner] = React.useState(true);
  const { isSignedIn } = useAuth();

  Intercom({
    app_id: 'khe0tarm',
  });

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <Header />
        <Sidebar />
        <Box
          component="main"
          className="MainContent"
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            minHeight: '100vh',
            position: 'relative',
            pt: {
              xs: 'var(--Header-height)',
              md: 1,
            },
          }}
        >
          {/* Sticky Mobile App Banner - Below header */}
          {showBanner && (
            <Box
              sx={{
                position: 'sticky',
                top: 'var(--Header-height)',
                zIndex: 1100,
                display: { xs: 'block', sm: 'none' },
                bgcolor: 'background.body',
              }}
            >
              <Alert
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                startDecorator={<GetAppRoundedIcon />}
                endDecorator={
                  <IconButton
                    variant="plain"
                    size="sm"
                    onClick={() => setShowBanner(false)}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                }
              >
                <Box sx={{ flex: 1 }}>
                  <Typography level="body-sm">
                    Get the Poshwatch mobile app
                  </Typography>
                </Box>
                <Button
                  size="md"
                  variant="solid"
                  component="a"
                  href="https://apps.apple.com/us/app/poshwatch-reseller-tools/id6502268040?platform=iphone"
                  target="_blank"
                  sx={{
                    borderRadius: '6px',
                    px: 3,
                    fontWeight: 600,
                    textTransform: 'none',
                    '&:hover': {
                      transform: 'translateY(-1px)',
                      transition: 'transform 0.2s',
                    }
                  }}
                >
                  Download
                </Button>
              </Alert>
            </Box>
          )}
          
          <Box
            sx={{
              px: { xs: 3 },
              display: 'flex',
              flexDirection: 'column',
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <CustomBreadcrumbs />
              {
                isSignedIn !== null && !isSignedIn &&  (
                  <Button 
                    sx={{
                      display: { xs: 'none', sm: 'none', md: 'block' },
                      backgroundColor: colors.elfGreen,
                      borderRadius: '6px',
                      color: 'white',
                      '&:hover': {
                        transform: 'translateY(-1px)',
                        transition: 'transform 0.2s',
                        backgroundColor: colors.elfGreen,
                      }
                    }} 
                    href={PATHS.REGISTER} 
                    component="a"
                  >
                    Sign up for free
                  </Button>
                )
              }
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              overflow: 'auto',
            }}
          >
            {children}
          </Box>
          <Box
            sx={{
              borderTop: '1px solid #dfe1e4',
              bgcolor: '#fbfcfe',
              p: {
                xs: 4,
              }
            }}
          >
            <NewsletterPanelV2 />
          </Box>
        </Box>
      </Box>
    </CssVarsProvider>
  );
}