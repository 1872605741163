import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { formatTotalPrice } from '../utils/helpers';

import React, { useState } from 'react';

export interface ItemRow {
  id: string;
  title: string;
  image: string;
  href: string;
  price: number;
}

export const ItemsGrid: React.FC<{
  items: ItemRow[];
  columns: number;
}> = ({ items, columns }) => {
  const [imageError, setImageError] = useState<{ [key: string]: boolean }>({});

  const handleImageError = (id: string) => {
    setImageError((prev) => ({ ...prev, [id]: true }));
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={1}>
      {items.map((item, index) => (
        <Grid item xs={12 / columns} key={index}>
          <Box
            component={item.href === "#" ? 'div' : 'a'}
            href={item.href}
            target="_blank"
            sx={{
              textDecoration: 'none',
              display: 'block',
              position: 'relative',
              overflow: 'hidden',
              borderRadius: '4px',
              border: '1px solid #ccc',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                paddingTop: '100%',
                backgroundColor: '#e0e0e0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
              }}
            >
              {item.image && !imageError[item.id] && (
                <Box
                  component="img"
                  src={item.image}
                  alt={`image-${index}`}
                  onError={() => handleImageError(item.id)}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              )}
              <Typography
                variant="body2"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  color: 'white',
                  padding: '0.2rem 0.5rem',
                  borderTopLeftRadius: '4px'
                }}
              >
                {formatTotalPrice(item.price)}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};