import React from 'react';
import { Box, CardContent, Typography } from '@mui/material';

const RankingPageDetails = () => {
  const paragraphStyle = {
    marginBottom: '50px',
    lineHeight: '1.6'
  };

  const h2Style = { fontSize: '1.8rem', marginBottom: '40px', fontFamily: "Libre Baskerville" }
  const h3Style = { fontSize: '1.4rem', marginTop: '20px', marginBottom: '20px', fontFamily: "Libre Baskerville" }
  const liStyle = { marginBottom: '15px'}
  const sectionStyle = { marginBottom: '80px' }

  return (
    <CardContent>
      <Box style={sectionStyle}>
          <Typography variant='h2' style={h2Style}>3 Factors Affecting Your Listing's Poshmark Search Ranking</Typography>
          <Typography variant='h3' style={h3Style}>
            1. Title
          </Typography>
          <Typography variant='body1' style={paragraphStyle}>
            The <strong>title</strong> of your listing is one of the most critical factors influencing your ranking in search results. Listings with titles that closely match a user's search query are more likely to rank higher. 
            To optimize your title:
            <ul>
              <li style={liStyle}>Include relevant keywords that buyers are likely to search for. Include the brand, item type and style. eg. 'Coach Cargo Tote Bag'.</li>
              <li>Balance being close to what users are searching for with being specific. Add details such as color, condition, and size. Buyers who have specific queries are often more ready to make a purchase. Eg. 'NWT Lululemon Align Pants Blue Size 8'.</li>
            </ul>
          </Typography>
          <Typography variant='h3' style={h3Style}>
            2. Shares
          </Typography>
          <Typography variant='body1' style={paragraphStyle}>
            <strong>Shares</strong> are the next important factor, because Poshmark’s default sorting is "Just Shared". This sorts their search results by most recently shared. Therefore, if you want to maintain a top rank in this sorting order:
            <ul>
                <li style={liStyle}>Share your listings ofen to maintain their visibility in search results.</li>
                <li>The more recent the share, the better your chances of appearing at the top of the list.</li>
            </ul>
          </Typography>
          <Typography variant='h3' style={h3Style}>
            3. Listing Age
          </Typography>
          <Typography variant='body1' style={paragraphStyle}>
            Based on our observation <strong>Listing Age</strong> seems to play the thrid most important role in how your listing is ranked. Poshmark tends to favor newer listings, so keeping your listings fresh is essential:
            <ul>
              <li>Relist older items to give them a better chance of ranking higher. We recommed relisting after 30 days but sooner is ok as well.</li>
            </ul>
          </Typography>
      </Box>
    </CardContent>
  );
};

export default RankingPageDetails;
