import axios from "axios";
import { buildEndpoint } from "../utils/helpers";

// fetchListingAnalysis: "http://127.0.0.1:5001/alice-tools-a93f3/us-central1/app/api/analysis/"
// fetchCoverPhotoAnalysis: "http://127.0.0.1:5001/alice-tools-a93f3/us-central1/app/api/photo-analysis/"
// getClosetReport: "http://127.0.0.1:5001/alice-tools-a93f3/us-central1/app/api/v2/closet-report"

const ENDPOINTS = {
  fetchListingAnalysis: "https://poshwatch.io/api/analysis/",
  fetchCoverPhotoAnalysis: "https://poshwatch.io/api/photo-analysis/"
};

export const getChart = async (type = 'brands', includeLuxury = true) => {
  try {
    const endpoint = "https://poshwatch.io/api/v2/charts";
    const url = `${endpoint}?type=${type}&includeLuxury=${includeLuxury}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("getChart Error:", error);
    return null;
  }
};

export const getStats = async ({ userId = null, username = null, brand = null } = {}) => {
  try {
    if (!userId && !username && !brand) {
      throw new Error("Please provide either userId, username, or brand");
    }

    const endpoint = "https://poshwatch.io/api/stats/";
    const queryParams = new URLSearchParams();

    if (userId !== null) queryParams.append("userId", userId);
    if (username !== null) queryParams.append("username", username);
    if (brand !== null) queryParams.append("brand", brand);

    const url = `${endpoint}?${queryParams.toString()}`;
    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    console.error("getStats Error:", error);
    return null;
  }
};

export const getDetails = async ({ type, id } = {}) => {
  try {
    if (!id && !type) {
      throw new Error("Missing type or id");
    }
    
    const endpoint = "https://poshwatch.io/api/v2/details";
    const queryParams = new URLSearchParams();

    if (id !== null) queryParams.append("id", id);
    if (type !== null) queryParams.append("type", type);

    const url = `${endpoint}?${queryParams.toString()}`;
    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    console.error("getDetails Error:", error);
    return null;
  }
};

export const getListingAnalysis = async ({ id = null } = {}) => {
  try {
    if (!id) {
      throw new Error("Please provide an id");
    }

    const endpoint = "https://poshwatch.io/api/analyze-listing/";
    const queryParams = new URLSearchParams();

    if (id !== null) queryParams.append("id", id);

    const url = `${endpoint}?${queryParams.toString()}`;
    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    console.error("getListingAnalysis Error:", error);
    return null;
  }
};

export const getRank = async ({ listingId = null, hostname = null, keywords = null, sort } = {}) => {
  console.log("getRank fetcing:");
  try {
    if (!listingId || !hostname || !keywords) {
      throw new Error("No listingId, hostname or keywords present");
    }

    const endpoint = "https://poshwatch.io/api/v2/listing-ranking";
    // const endpoint = "http://127.0.0.1:5001/alice-tools-a93f3/us-central1/app/api/v2/listing-ranking";
    const queryParams = new URLSearchParams();

    if (listingId !== null) queryParams.append("listingId", listingId);
    if (hostname !== null) queryParams.append("hostname", hostname);
    if (keywords !== null) queryParams.append("keywords", keywords);
    queryParams.append("sort", sort);

    const url = `${endpoint}?${queryParams.toString()}`;
    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    console.error("getRank Error:", error);
    return null;
  }
};

export const generateClosetReport = async ({ username = null, hostname = null } = {}) => {
  console.log("generateClosetReport:");
  try {
    if (!username || !hostname) {
      throw new Error("No username present");
    }

    const endpoint = "https://poshwatch.io/api/v2/closet-report";
    const queryParams = new URLSearchParams();

    if (username !== null) queryParams.append("username", username);
    if (hostname !== null) queryParams.append("hostname", hostname);

    const url = `${endpoint}?${queryParams.toString()}`;
    const response = await axios.get(url);
    // console.log("getClosetReport response:", response);
    return response.data;
  } catch (error) {
    console.error("generateClosetReport Error:", error);
    return null;
  }
};

export const getClosetReports = async ({ username = null, hostname = null } = {}) => {
  console.log("getClosetReports:");
  try {
    if (!username || !hostname) {
      throw new Error("No username present");
    }

    const endpoint = "https://poshwatch.io/api/v2/closet-reports";
    const queryParams = new URLSearchParams();

    if (username !== null) queryParams.append("username", username);
    if (hostname !== null) queryParams.append("hostname", hostname);

    const url = `${endpoint}?${queryParams.toString()}`;
    const response = await axios.get(url);
    // console.log("getClosetReport response:", response);
    return response.data;
  } catch (error) {
    console.error("getClosetReports Error:", error);
    return null;
  }
};

export const fetchListingAnalysis = async ({ listingURL } = {}) => {
  if (!listingURL) {
    throw new Error("Please provide a listing url");
  }

  try {
    const url = buildEndpoint(listingURL, ENDPOINTS.fetchListingAnalysis);
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("fetchListingAnalysis:", error.message);
    
    throw new Error(JSON.stringify({
      message: `${error?.response?.statusText || error.message} ${listingURL}`,
      statusCode: error?.response?.status,
    }));
  }
};

export const fetchCoverPhotoAnalysis = async ({ photoURL } = {}) => {
  try {
    const url = buildEndpoint(photoURL, ENDPOINTS.fetchCoverPhotoAnalysis);
    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    console.error("fetchCoverPhotoAnalysis Error:", error);
    throw new Error(`fetchCoverPhotoAnalysis ${error}, ${photoURL}`);
  }
};

export const subscribeWithEmail = async (email) => {
  const endpoint = `https://poshwatch.io/api/subscribe/?email=${encodeURIComponent(email)}`;

  try {
    await axios.get(endpoint);
    return true;
  } catch (error) {
    console.error("subscribeWithEmail Error:", error);
    return false;
  }
};

export const subscriptions = async (userId) => {
  try {
    const endpoint = `https://poshwatch.io/api/v2/subscriptions?userId=${userId}`;
    const response = await axios.get(endpoint);
    return response.data.active;
  } catch (error) {
    console.error("subscriptions Error:", error);
    return false;
  }
};

export const appWelcomeSequence = async (email) => {
  console.log("welcome", email);
  try {
    const endpoint = `https://poshwatch.io/api/v2/welcome?email=${email}`;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    console.error("subscriptions Error:", error);
    return false;
  }
};

export const getUserSettings = async (userId) => {
  try {
    const url = `https://poshwatch.io/api/v2/users?userId=${userId}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error getting user:", error);
    return null;
  }
}

export const search = async (query, limit) => {
  try {
    const url = `https://poshwatch.io/api/v2/search?query=${query}&limit=${limit}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
