import {
  initializeApp,
  FirebaseApp,
} from "firebase/app";

import {
  getFirestore,
  Firestore,
} from "@firebase/firestore";

import {
  getAuth,
  Auth,
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  UserCredential,
} from "firebase/auth";

import { Analytics } from 'monolib';

const firebaseConfig = {
  apiKey: "AIzaSyAuT5fgWrb44AtwRHcA4vhNRuw766tkLZE",
  authDomain: "auth.poshwatch.io",
  projectId: "alice-tools-a93f3",
  storageBucket: "alice-tools-a93f3.appspot.com",
  messagingSenderId: "277776691102",
  appId: "1:277776691102:web:7407d11522ae452e51b08f",
  measurementId: "G-6J0HYY6LQJ"
};

export class MyFirebase {
  private static instance: MyFirebase;
  private _db: Firestore;
  private _auth: Auth;
  private _app: FirebaseApp;

  constructor() {
    const app = initializeApp(firebaseConfig);
    this._db = getFirestore(app);
    this._auth = getAuth(app);
    this._app = app;
  }

  public static getInstance(): MyFirebase {
    if (!MyFirebase.instance) {
      MyFirebase.instance = new MyFirebase();
    }
    return MyFirebase.instance;
  }

  public getFirestore(): Firestore {
    return this._db;
  }

  public getAuth(): Auth {
    return this._auth;
  }

  public getApp(): FirebaseApp {
    return this._app;
  }

  public signOut() {
    Analytics.instance.track("sign_out", {})
    return this._auth.signOut();
  }

  // Method to sign in with Facebook
  public async signInWithFacebook(): Promise<UserCredential> {
    const provider = new FacebookAuthProvider();
    try {
      const userCredential = await signInWithPopup(this._auth, provider);
      Analytics.instance.track("sign_in", { provider: "facebook" })
      return userCredential;
    } catch (error) {
      console.error(error);
      Analytics.instance.track("sign_in_error", { provider: "facebook", error })
      throw new Error("Failed to sign in with Facebook");
    }
  }

  // Method to sign in with Google
  public async signInWithGoogle(): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(this._auth, provider);
      Analytics.instance.track("sign_in", { provider: "google" })
      return userCredential;
    } catch (error) {
      console.error(error);
      Analytics.instance.track("sign_in_error", { provider: "google", error })
      throw new Error("Failed to sign in with Google");
    }
  }

  // Method to sign in with Apple
  public async signInWithApple(): Promise<UserCredential> {
    const provider = new OAuthProvider('apple.com');
    try {
      const userCredential = await signInWithPopup(this._auth, provider);
      Analytics.instance.track("sign_in", { provider: "apple" })
      return userCredential;
    } catch (error) {
      console.error(error);
      Analytics.instance.track("sign_in_error", { provider: "apple", error })
      throw new Error("Failed to sign in with Apple");
    }
  }

  async signInWithEmail(email: string, password: string): Promise<boolean> {
    try {
      await signInWithEmailAndPassword(this._auth, email, password);
      Analytics.instance.track("sign_in", { provider: "email" })
      return true;
    } catch (error) {
      console.error("Error logging in: ", error);
      Analytics.instance.track("sign_in_error", { provider: "email", error })
      return false;
    }
  }

  async registerWithEmail(email: string, password: string): Promise<UserCredential|null> {
    try {
      const userCredential = await createUserWithEmailAndPassword(this._auth, email, password);
      Analytics.instance.track("sign_up", { provider: "email" })
      return userCredential;
    } catch (error) {
      Analytics.instance.track("sign_up_error", { provider: "email", error })
      throw error;
    }
  }
}
