import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Tabs,
  Tab,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  styled,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { colors, lightTheme } from '../customTheme';
import PrimaryButton from './PrimaryButton';
import ScreenSearchDesktopTwoToneIcon from '@mui/icons-material/ScreenSearchDesktopTwoTone';
import ImageSearchTwoToneIcon from '@mui/icons-material/ImageSearchTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PATHS from '../utils/paths';
import SearchSuggestions from './SearchSuggestions.tsx';
import { useNavigate } from 'react-router-dom';
import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 500,
  borderRadius: theme.spacing(1),
  '& .MuiCardContent-root': {
    padding: theme.spacing(0),
  },
  border: `1px dashed #939598`,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23f0f0f0' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
    opacity: 0.05,
    pointerEvents: 'none',
  },
  boxShadow: `-8px 8px 0px 0px ${colors.wafer}`,
}));

const CustomTab = styled(Tab)({
  textTransform: 'none',
});

const ToolsCard: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [query, setQuery] = useState('');
  const [urlQuery, setUrlQuery] = useState('');
  const navigate = useNavigate();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleProductSearch = () => {
    if (query.trim() !== '') {
      navigate(`${PATHS.PRODUCT_RESEARCH}?q=${query}`);
    }
  };

  const handleListingAnalysis = () => {
    if (urlQuery.trim() !== '') {
      window.location.href = `${PATHS.LISTING_ANALYZER}?q=${urlQuery}`;
    }
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <StyledCard sx={{
        pt: { xs: 2, sm: 3 },
        pr: { xs: 2, sm: 5 },
        pb: { xs: 1, sm: 1 },
        pl: { xs: 2, sm: 5 },
        ml: { xs: 1, sm: 0 },
      }}>
        <CardContent>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="tools tabs"
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            sx={{ pb: 1, display: 'flex', justifyContent: 'center' }}
          >
            <CustomTab
              icon={<ScreenSearchDesktopTwoToneIcon style={{ fontSize: "2em" }} />}
              label="Product Research"
            />
            <CustomTab
              icon={<AutoFixHighTwoToneIcon style={{ fontSize: "2em" }} />}
              label="Closet Sharer"
            />
            <CustomTab
              icon={<ImageSearchTwoToneIcon style={{ fontSize: "2em" }} />}
              label="Listing Analyzer"
            />
          </Tabs>

          {tabValue === 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h3" sx={{ mt: 4, mb: 1 }} style={{ fontSize: "1.4em", textAlign: "center", fontFamily: "Libre Baskerville" }}>
                Find top-selling products on Poshmark
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Enter a product description"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleProductSearch();
                  }
                }}
                sx={{
                  mt: 2,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                  },
                  width: '100%',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleProductSearch}
                        disabled={query.trim() === ''}
                      >
                        <ArrowForwardIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <SearchSuggestions
                count={3}
                onSuggestionClick={(suggestion) => {
                  navigate(`${PATHS.PRODUCT_RESEARCH}?q=${suggestion}`)
                }}
              />
            </Box>
          )}
          {tabValue === 1 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h3" sx={{ mt: 4, mb: 1 }} style={{ fontSize: "1.4em", textAlign: "center", fontFamily: "Libre Baskerville" }}>
                Ready to grow? We automate closet sharing, to increase followers and sales.
              </Typography>
              <PrimaryButton
                href={PATHS.POSHMARK_ASSISTANT}
                variant="contained"
                color="primary"
                style={{
                  paddingLeft: 80,
                  paddingRight: 80,
                  marginTop: 30,
                  backgroundColor: colors.mineShaft,
                  color: 'white',
                }}
              >
                Try it now!
              </PrimaryButton>
            </Box>
          )}
          {tabValue === 2 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h3" sx={{ mt: 4, mb: 1 }} style={{ fontSize: "1.4em", textAlign: "center", fontFamily: "Libre Baskerville" }}>
                Items not selling? Let's find out why so you can boost your sales.
              </Typography>
              <TextField
                value={urlQuery}
                fullWidth
                variant="outlined"
                placeholder="Enter a Poshmark listing URL"
                onChange={(e) => setUrlQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleListingAnalysis();
                  }
                }}
                sx={{
                  mt: 2,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                  },
                  width: '100%',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleListingAnalysis}
                        disabled={urlQuery.trim() === ''}
                      >
                        <ArrowForwardIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
        </CardContent>
      </StyledCard>
    </ThemeProvider>
  );
};

export default ToolsCard;