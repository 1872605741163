/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import { Avatar, Container, Chip, Link, Typography } from '@mui/material';
import { getChart } from '../api/client';
import { Helmet } from 'react-helmet-async';
import { colors } from '../customTheme';
import Skeleton from '@mui/material/Skeleton';
import { formatNumberCompact, categoryColors } from '../utils/helpers';
import ToolDetailSection from '../components/tool-content/ToolDetailSection';
import Dashboard from './dashboard/Dashboard.tsx';
import { Experimental_CssVarsProvider } from '@mui/material/styles';
import DataTable from '../components/DataTable.tsx';
import DataTableFilters from '../components/DataTableFilters.tsx';

export const NameCell = ({ name, href, newTab }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Link
        style={{ textDecoration: 'none' }}
        href={href}
        target={newTab ? '_blank' : '_self'}
      >
        {name}
      </Link>
    </div>
  );
};

export const CustomChip = ({ showAvatar, name, count, index }) => {
  return <Chip
    avatar={
      showAvatar ? <Avatar
        style={{
          fontSize: 9,
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: categoryColors[name] ?? '#888',
        }}
      >
        {/* {category.name[0]} */}
        {formatNumberCompact(count)}
      </Avatar> : undefined
    }
    key={index}
    label={name}
    style={{
      marginRight: '4px',
      fontSize: 11,
      marginBottom: '4px',
    }}
  />
}

export const TopPage = ({ type, columns, title, subtitle, description, DetailsSection, href, toggleOptions }) => {
  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [_, setTimeFrame] = useState(toggleOptions[0].value);
  const [includeLuxury, setIncludeLuxury] = useState(toggleOptions[1] ? toggleOptions[1].value : false);
  const [loading, setLoading] = useState(false);

  const onFilterChanged = (props) => {
    const {filterName, value} = props;
    if (filterName === 'include-luxury') {
      setIncludeLuxury(value);
    }
    else if (filterName === 'date-range') {
      setTimeFrame(value);
    }
  }

  useEffect(() => {
    setLoading(true);

    getChart(type, includeLuxury)
      .then((result) => {

        const filteredData = result?.results
          ?.filter(item => item.name.toLowerCase() !== "unknown")
          ?.map((item, index) => {
            return {
              id: index + 1,
              name: item.name,
              totalPrice: item.sales,
              soldItemsCount: item.itemsSold,
              averageSalePrice: item.averagePrice,
              categories: item.topCategories,
              brands: item.topBrands,
              // This is required so we can show the image url and name in the same column for top sellers page
              sellerInfo: {
                name: item.name,
                imageUrl: item.image_url,
              }
            };
          });

        setData(filteredData);

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });

  }, [includeLuxury, type]);

  const SkeletonRows = () => {
    const skeletonRows = Array.from({ length: 5 }, (_, index) => (
      <Skeleton key={index} height={48} animation="pulse" />
    ));

    return <Container maxWidth={false}>{skeletonRows}</Container>;
  };

  return (
    <Dashboard>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://poshwatch.io/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Container maxWidth={false} sx={{ pt: 4 }}>
        <Typography variant='h1' style={{
          fontSize: "1.8em",
          fontWeight: 'bold',
          color: colors.mineShaft,
          fontFamily: "Libre Baskerville"
        }}>{title}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body1"
          sx={{ pb: 2, pt: 0 }}
        >{subtitle}
        </Typography>
      </Container>
      <Container maxWidth={false} style={{ minHeight: "800px" }}>
        <div style={{ width: '100%' }}>

          <DataTableFilters
            filters={toggleOptions}
            onChange={onFilterChanged}
            sx={{ pb: 2 }} />

          {!loading &&
            <DataTable columns={columns} rows={data} />
          }

          <Experimental_CssVarsProvider>
            {loading && <SkeletonRows />}
          </Experimental_CssVarsProvider>
        </div>
      </Container>
      <ToolDetailSection type={type} />

    </Dashboard>
  );
};
