import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const FeeCalculatorDetails = ({ compact }) => {
  const paragraphStyle = {
    marginBottom: '15px',
    lineHeight: '1.6'
  };

  const h2Style = { fontSize: '1.8rem', marginTop: '20px', marginBottom: '20px', fontFamily: "Libre Baskerville", fontWeight: 'bold' }
  const questionStyle = { fontSize: '1.4rem', marginTop: '20px', marginBottom: '20px', fontWeight: 'bold' }
  const sectionStyle = { marginBottom: '40px' }

  return (
    <Box py={4}>
      <Container maxWidth={false}>
        <Box style={sectionStyle}>
          <Typography variant='h2' style={h2Style}>How to use the fee calculator</Typography>
          <Typography variant='body1' style={paragraphStyle}>
            Using our Poshmark Fee Calculator is straightforward.
          </Typography>
          <Typography variant='body1' style={paragraphStyle}>
            Just input your item’s sales and cost price, and the calculator will instantly display Poshmark’s fee and your net earnings. This helps you understand exactly how much you’ll make from each sale, allowing you to price your items confidently.
          </Typography>
          <Typography variant='body1' style={paragraphStyle}>
            Understanding Poshmark fees is crucial for you as a seller as it directly impacts your profit margins, it enables better planning, by helping you to price items in a way that best suites your sales goals and avoid unexpected deductions.
          </Typography>
        </Box>
        <Box >
          <Typography variant='h2' style={h2Style}>Poshmark fee calculator FAQ</Typography>
          <Typography variant='body1' style={questionStyle}>
            What is Poshmark's standard fee structure?
          </Typography>
          <Typography variant='body1' style={paragraphStyle}>
            Poshmark charges a flat fee of $2.95 for sales under $15. For sales of $15 or more, Poshmark takes a 20% commission. This fee structure is designed to simplify the selling process and ensure transparency.
          </Typography>
          <Typography variant='body1' style={paragraphStyle}>
            While the calculator is designed for sellers, buyers can also use it to understand how much sellers are paying in fees, which can help in negotiations and making informed purchasing decisions.
          </Typography>
          <Typography variant='body1' style={questionStyle}>
            How does this Poshmark fee calculator account for shipping costs?
          </Typography>
          <Typography variant='body1' style={paragraphStyle}>
            The fee calculator focuses on Poshmark's selling fees. Shipping costs are typically paid by the buyer, but in cases where the seller offers discounted or free shipping, those costs are not included in the calculator’s fee deduction.
          </Typography>
          <Typography variant='body1' style={questionStyle}>
            Can this Poshmark fee calculator help me decide my listing price?
          </Typography>
          <Typography variant='body1' style={paragraphStyle}>
            It can definitely help! That is the goal of making this calculator, to help you with pricing. By understanding the fees deducted from your potential sale price, you can set a price that maximizes your profit while remaining competitive.
          </Typography>
          <Typography variant='body1' style={questionStyle}>
            Does this Poshmark fee calculator include any additional costs or taxes?
          </Typography>
          <Typography variant='body1' style={paragraphStyle}>
            The calculator only includes Poshmark's standard selling fees. Additional costs such as shipping, taxes, or promotional expenses are not included. Poshmark does not deduct taxes from your payout, it leaves that for the seller to handle. It's important to consider these when finalizing your pricing strategy.
          </Typography>
          <Typography variant='body1' style={paragraphStyle}>
            Poshmark Canada operates differently, as of July 1 2022, Poshmark is required to charge and collect GST/HST, QST, and PST to canadian shoppers.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default FeeCalculatorDetails;