import { Solver } from "2captcha";

const captchaConfig = {
  key: '1f19dda3c1af9ad401979865bd6d71dd', // 2Captcha key
  // To get this key go to poshmark and share an item
  // The captcha dialog pops up, search the source for data-sitekey
  siteKey: '6Lc6GRkTAAAAAK0RWM_CD7tUAGopvrq_JHRrwDbD' // Poshmark site key
}

const solver = new Solver(captchaConfig.key)

export const solve = async ({ pageUrl }: { pageUrl: string }): Promise<any> => {
  return await solver.recaptcha(captchaConfig.siteKey, pageUrl)
}