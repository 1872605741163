/* eslint-disable no-unused-vars */
export enum URLS {
  CA = 'https://poshmark.ca',
  US = 'https://poshmark.com',
  CA_SHORT = 'poshmark.ca',
  US_SHORT = 'poshmark.com',
  CA_LOGIN = 'https://poshmark.ca/login',
  US_LOGIN = 'https://poshmark.com/login',
}

export enum COOKIES { UI = 'ui' }

export enum COUNTRY_CODE {
  CA = 'ca',
  US = 'us'
}

export enum CURRENCY_CODE {
  CAD = 'CAD',
  USD = 'USD'
}

export enum TARGETS {
  FOLLOWERS = 'followers',
  PARTY = 'party',
}

//
// Actions that require message passing
//
export enum ACTIONS {
  LIKE = 'like',
  FOLLOW = 'follow',
  UNFOLLOW = 'unfollow',
  SHARE = 'share',
  SHARE_PARTY = 'share_party',
  OFFER_TO_LIKERS = 'offer_to_likers',
  CHECK_LOGIN = 'check_login',
  GET_CURRENT_URL = 'get_current_url',
  STOP_TASK = 'stop_task',
  RESUME_TASK = 'resume_task',
  REDIRECT_TO_SETTINGS = 'redirect_to_settings', // This action is handled by the options page to redirect to settings
}

export enum RESTRICTED_ACTIONS {
  SHARE_POST = 'share_post',
  FOLLOW_PERSON = 'follow_person',
  UNFOLLOW_PERSON = 'unfollow_person'
}

export enum TIME {
  ONE_DAY = 24 * 60 * 60 * 1000
}

// Number of items to show in recent activity grid view
export const ACTIVITY_ITEM_LIMIT = 16

// Absolute limit for number of items in activity
export const ABSOLUTE_ITEM_LIMIT = 100

export const TRIAL_LIMIT = 1000

export enum POSH_ERRORS {
  SUSPECTED_BOT_ERROR = 'SuspectedBotError',
  SHARE_POST_REQUEST_LIMIT_EXCEEDED_ERROR = 'SharePostRequestLimitExceededError',
  RATE_LIMIT_EXCEEDED = 'RateLimitExceeded',
  INSUFFICIENT_PRIVILEGES_ERROR = 'InsufficientPrivilegesError' // " Sorry! You cannot currently like listings from this seller."
}

export const getPastTense = (action: ACTIONS) => {
  switch (action) {
    case ACTIONS.LIKE:
      return 'liked'
    case ACTIONS.FOLLOW:
      return 'followed'
    case ACTIONS.UNFOLLOW:
      return 'unfollowed'
    case ACTIONS.SHARE:
      return 'shared to followers'
    case ACTIONS.SHARE_PARTY:
      return 'shared to party'
    case ACTIONS.OFFER_TO_LIKERS:
      return 'made an offer to likers for'
    default:
      return 'unknown'
  }
}

// These codes are used to mark certain notifications as read
export enum NOTIFICATION_CODES {
  ALL = 3400,
  OFFERS = 1351,
  COMMENTS = 1005,
  LIKES = 1061,
  BUNDLES = 1032,
  STORIES = 1000, // Unknown, need to find the code for this
  SHARES = 1330,
  FOLLOWS = 1647,
  ORDERS = 1000, // Unknown, need to find the code for this
  ACCOUNT = 1001
}

export const getCountryCode = (hostname: string) => hostname.includes('poshmark.ca') ? COUNTRY_CODE.CA : COUNTRY_CODE.US
export const getCurrencyCode = (hostname: string) => hostname.includes('poshmark.ca') ? CURRENCY_CODE.CAD : CURRENCY_CODE.USD