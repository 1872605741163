import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const AnalyzerBenefits = ({ compact }) => {

  return (
    <Box bgcolor="#ffffff" py={4} >
      <Container maxWidth='lg' align='center' style={{ marginTop: "65px", marginBottom: "65px" }}>
        <Box mb={4}>
          <Typography variant="body1" align='left' gutterBottom>
            Benefits of Listing Analyzer
          </Typography>
          {!compact && (
            <Typography variant='h4' style={{ fontSize: '2.0rem', fontFamily: "Libre Baskerville" }} align='left' gutterBottom>
              {`Increase listing visibility & performance`}
            </Typography>
          )}
        </Box>
        <Box>
          <Box display="flex" alignItems="left" mb={2}>
            <CheckIcon color="primary" style={{ marginRight: '8px' }} />
            <Typography variant="body1" color="textSecondary" align='left'>
              Receive valuable insights on areas for listing improvement
            </Typography>
          </Box>
          <Box display="flex" alignItems="left" mb={2}>
            <CheckIcon color="primary" style={{ marginRight: '8px' }} />
            <Typography variant="body1" color="textSecondary" align='left'>
              Access a detailed checklist that covers all aspects of your listing
            </Typography>
          </Box>
          <Box display="flex" alignItems="left" mb={2}>
            <CheckIcon color="primary" style={{ marginRight: '8px' }} />
            <Typography variant="body1" color="textSecondary" align='left'>
              Outperform your competitors
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AnalyzerBenefits;