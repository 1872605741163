import {
  TIME,
  RESTRICTED_ACTIONS,
  POSH_ERRORS,
  NOTIFICATION_CODES,
  ACTIONS,
  getCurrencyCode
}
  from './constants'
import { solve } from '../utils/captcha'
import { ShippingDiscountResponse } from './types/shipping_discount_response'
import { NewsFeedItem, NewsFeedResponse } from './types/news_feed_response'
import { PostResponse } from './types/post_response'
import { ShippingDiscountLabel } from './types/shipping_discount_labels'
import { NotificationsResponse } from './types/notifications_response'

// Number of items per request
const CLOSET_PAGE_SIZE: number = 48

const FOLLOWER_PAGE_SIZE: number = 48

// Number of items to retrieve for each list request
// 2400 is to much and will result in a `QUOTA_BYTES quota exceeded` in chrome storage
const BATCH_SIZE: number = 1200

export default class PoshAPI {
  private static instance: PoshAPI;
  user?: string;
  uid?: string;
  cookie?: string;

  public static getInstance(): PoshAPI {
    if (!PoshAPI.instance) {
      PoshAPI.instance = new PoshAPI();
    }
    return PoshAPI.instance;
  }

  /*
   * Auth request on steroids (With captcha solver)
   */
  async authenticateWithCaptcha({ username, password, hostname }: { username: string, password: string, hostname: string }) {
    let response = await this.authenticate({ username, password, hostname });
    const error = response.error;

    if (error != null && error.errorType.includes(POSH_ERRORS.SUSPECTED_BOT_ERROR)) {
      const captchaResponse = await solve({ pageUrl: `https://${hostname}` });
      response = await this.authenticate({ username, password, hostname, captchaResponse: captchaResponse.data });
    }

    return response;
  }

  /*
   * Bare bones auth request
   */
  async authenticate({ username, password, hostname, captchaResponse }: { username: string, password: string, hostname: string, captchaResponse?: string }) {
    const data = {
      user_handle: username,
      password: password,
    } as any;

    if (captchaResponse != null) {
      data['g-recaptcha-response'] = captchaResponse;
      data.render_captcha = true;
    }

    const url = encodeURI(`https://${hostname}/vm-rest/auth/users/access_token`);
    const headers = {
      'authority': hostname,
      'sec-ch-ua': '" Not A;Brand";v="99", "Chromium";v="90", "Google Chrome";v="90"',
      'accept': 'application/json',
      'sec-ch-ua-mobile': '?0',
      'content-type': 'application/json',
      'origin': `https://${hostname}`,
      'sec-fetch-site': 'same-origin',
      'sec-fetch-mode': 'cors',
      'sec-fetch-dest': 'empty',
      'referer': `https://${hostname}/login`,
      'accept-language': 'en-US,en;q=0.9',
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      });

      const body = await response.json();

      if (body.data.error) {
        console.log(`Error authenticating ${username}`, body.data.error);
      }

      return body.data;
    } catch (error) {
      console.error('Error during authentication:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }

  /*
   * Gets a post
   */
  async getPost({ id, hostname }: { id: string, hostname: string }): Promise<PostResponse | null> {
    const url = encodeURI(`https://${hostname}/vm-rest/posts/${id}`);

    try {
      const response = await fetch(url, {
        method: 'GET'
      });

      const body = await response.json();

      if (body.error) {
        console.log(`Error getting item ${id}`, body.error);
        return null;
      }
      else {
        return body as PostResponse;
      }
    } catch (error) {
      console.error('Error getting item:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }

  /*
   * Share a post by id to my followers
   * There is validation for sharing to parties, some listings will fail with
   * error.errorType === "ValidationError"
   */
  async share({ id, hostname, eventIds }: { id: string, hostname: string, eventIds?: string }) {
    let url = encodeURI(`https://${hostname}/vm-rest/users/self/shared_posts/${id}`);
    if (eventIds !== undefined && eventIds !== null) {
      url = encodeURI(`https://${hostname}/vm-rest/users/self/shared_posts/${id}?event_ids=${eventIds}`);
    }

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Cookie': this.cookie ?? '',
        }
      });

      const json = await response.json();

      return json;
    } catch (error) {
      console.error(`Error sharing post ${id}`, error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }

  /*
   * Gets the shipping discount for an item
   * Price is the discount price with the percentage already applied
   * You only need to get the shipping discount for 1 item. All the shipping discount Ids are the same and the price does not matter.
   */
  async getShippingDiscount({ id, price, hostname }: { id: string, price: number, hostname: string }): Promise<ShippingDiscountResponse> {
    const currencyCode = getCurrencyCode(hostname);

    const params = new URLSearchParams({
      request: JSON.stringify({ val: price, currency_code: currencyCode, object_id: id })
    });

    const url = encodeURI(`https://${hostname}/vm-rest/users/${this.uid}/seller_shipping_discounts/offer`);

    try {
      const response = await fetch(`${url}?${params}`, {
        method: 'GET',
        headers: {
          'Cookie': this.cookie ?? '',
        }
      });

      const body = await response.json();

      if (body.error) {
        console.error(`Error getting discount ${id}`, response.statusText);
        throw new Error(`Error getting discount ${id}`);
      }

      return body as ShippingDiscountResponse;
    } catch (error) {
      console.error(`Error getting discount ${id}`, error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }

  /*
 * Sends an offer
 * Price is the discount price with the percentage already applied
 */
  async offer({ id, price, shippingDiscountId, hostname }: { id: string, price: number, shippingDiscountId: string, hostname: string }) {
    const currencyCode = getCurrencyCode(hostname);

    const data = {
      offer_amount: {
        val: price,
        currency_code: currencyCode
      },
      seller_shipping_discount: {
        id: shippingDiscountId
      },
      offer_api_version: 3,
    };

    const url = encodeURI(`https://${hostname}/vm-rest/posts/${id}/likes/users/offers`);

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Cookie': this.cookie ?? '',
        }
      });

      if (!response.ok) {
        const errorMessage = `Error sending offer ${id}: ${response.statusText}`;
        console.error(errorMessage);
        throw new Error(errorMessage);
      }

      return await response.json();
    } catch (error) {
      console.error('Error sending offer:', error);
      throw error;
    }
  }

  async getNotifications({
    userId,
    hostname,
  }: {
    userId: string,
    hostname: string
  }): Promise<NotificationsResponse | null> {
    const url = encodeURI(
      `https://${hostname}/vm-rest/users/${userId}/state/summary?notifications=true&user_info=false&sizes=false&user_shows=false&tags=false`
    );

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Cookie': this.cookie ?? '',
        }
      });

      const body = await response.json();

      if (body.error) {
        console.log(`Error getting user state summary for ${userId}`, body.error);
        return null;
      } else {
        return body as NotificationsResponse;
      }
    } catch (error) {
      console.error('Error getting user state summary:', error);
      throw error;
    }
  }

  async getNewsFeed(
    { type = ACTIONS.LIKE, maxId = "", hostname, filterOutRead = true, cutoffDate }:
      { type: string, maxId: string, hostname: string, filterOutRead: boolean, cutoffDate: Date }) {
    try {
      const queryString = maxId !== "" ? `?maxId=${maxId}` : '';
      const url = encodeURI(`https://${hostname}/vm-rest/users/${this.uid}/newsfeed/${type}${queryString}`);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Cookie': this.cookie ?? '',
        }
      });

      const jsonResponse: NewsFeedResponse = await response.json();
      const { error } = jsonResponse as any;

      if (error) {
        const errorMessage = `Error getting news feed ${type}: ${error?.errorType}`;
        console.error(errorMessage);
        throw new Error(errorMessage);
      }

      let hasOlderItems = false;

      let data = jsonResponse.data
        .filter(item => {
          const createdAtDate = new Date(item.content.data[0].news_item.created_at);
          if (createdAtDate < cutoffDate) {
            hasOlderItems = true;
            return false;
          }

          if (filterOutRead && item.content.data[0].news_item.read) {
            return false;
          }

          if (item.story_type !== 'PersonLikePost') {
            return false;
          }

          return true;
        })

      return {
        data,
        next_max_id: hasOlderItems ? null : jsonResponse?.more.next_max_id
      };
    } catch (error) {
      console.error('Error getting news feed:', error);
      throw error;
    }
  }

  async getNewsFeedBatch({
    type = ACTIONS.LIKE,
    count = BATCH_SIZE,
    filterOutRead = true,
    hostname,
    cutoffDate
  }:
    {
      type?: string,
      count?: number,
      filterOutRead?: boolean,
      hostname: string,
      cutoffDate: Date
    }) {
    let maxId: string | undefined = "";
    const items = [];

    try {
      do {
        const result: {
          data: NewsFeedItem[];
          next_max_id: string | null | undefined;
        } = await this.getNewsFeed({ type, maxId, filterOutRead, hostname, cutoffDate });

        items.push(...result.data);

        if (result.next_max_id !== null) {
          maxId = result.next_max_id;
        } else {
          maxId = undefined;
        }
      } while (maxId !== undefined && items.length < count);

      return items;
    } catch (error) {
      console.error('Error getting news feed batch:', error);
      throw error;
    }
  }


  /*
   * Checks the news feed then sends offer to likers
   */
  async offerToLikers({ discountPercentage = 0.2, shippingDiscountLabel, hostname, cutoffDate }: { discountPercentage: number, shippingDiscountLabel: ShippingDiscountLabel, hostname: string, cutoffDate: Date }) {
    const likedPosts = await this.getNewsFeedBatch({
      filterOutRead: false,
      hostname,
      cutoffDate
    })

    const uniqueLikedPosts = likedPosts.filter((item, index, self) =>
      index === self.findIndex((t) => (
        // See news_feed_response.json for an example of this data
        t.content.data[0].news_item.content.data[0].id
         === item.content.data[0].news_item.content.data[0].id
      ))
    );

    const offersMade: PostResponse[] = [];

    for (const item of uniqueLikedPosts) {
      const post = await this.getPost({ id: item.content.data[0].news_item.content.data[0].id, hostname })

      if (!post ||
        post.inventory.status !== 'available' ||
        post.brand === 'Meet the Posher') continue;

      const price = post.price - (post.price * discountPercentage)

      const shippingDiscount = await this.getShippingDiscount({ id: post.id, price: price, hostname })

      const shippingDiscountId = shippingDiscount.data.find((d) => d.label === shippingDiscountLabel)?.id ?? shippingDiscount.data[0].id;

      // If a previous offer is was made, the new offer needs to be a lower price otherwise the offer will fail
      // The offer will be made but if there are no new users who have liked the item since the orignal offer
      // then the user will get a notification saying no offers were sent out and you must lower the price
      // However in the Poshmark UI the user still sees a success message
      await this.offer({ id: post.id, price: price, shippingDiscountId, hostname })

      offersMade.push(post);
    }

    return offersMade;
  }

  /*
   * Get closet
   * count maximum is 50, if larger than 50 is specified then the api only returns 20 results
   */
  async getCloset({
    user = this.user,
    maxId,
    count = CLOSET_PAGE_SIZE,
    hostname = 'poshmark.com',
    inventoryStatus = 'all',
  }: {
    user?: string;
    maxId?: string;
    count?: number;
    hostname?: string;
    inventoryStatus?: string;
  }): Promise<any> {
    const url = `https://${hostname}/vm-rest/users/${user}/posts/filtered`;

    const params = new URLSearchParams({
      request: JSON.stringify({
        filters: { department: 'All', inventory_status: [inventoryStatus] },
        query_and_facet_filters: { creator_id: user },
        experience: 'all',
        max_id: maxId ?? "",
        count,
      }),
      summarize: 'true',
      app_version: '2.55',
      pm_version: '2024.9.0'
    });

    try {
      const response = await fetch(`${url}?${params}`, {
        method: 'GET'
      });

      const body = await response.json();

      // Need to return the entire body because getClostBatch depends on other properites other than data
      return body;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  /*
   * Get the latest posts from the users closet using pagination
   *
   * NOTE:
   * I'm not sure why but this function returns items in multiples of 48
   * If you specify a count of 5 it will return 48
   * If you specify a count of 49 it will return 96
   * Possibly has something to do with the maxId?
   */
  async getClosetBatch({
    user = this.user,
    count = BATCH_SIZE,
    hostname,
    inventoryStatus,
  }: {
    user?: string;
    count?: number;
    inventoryStatus?: string;
    hostname: string;
  }): Promise<PostResponse[]> {
    let maxId: string | undefined;

    const posts: PostResponse[] = [];

    do {
      const result = await this.getCloset({
        user,
        maxId,
        count,
        hostname,
        inventoryStatus,
      });

      if (result.data) {
        posts.push(...result.data);
      }

      if (result.more) {
        maxId = result.more.next_max_id;
      } else {
        maxId = undefined;
      }
    } while (maxId !== undefined && posts.length < count);

    return posts;
  }

  /*
   * Sends the captcha response back to poshmark for verification
   */
  async solveCaptcha({ restrictedAction = RESTRICTED_ACTIONS.SHARE_POST, hostname }: {
    restrictedAction?: string;
    hostname: string;
  }) {
    try {
      const captchaResponse = await solve({ pageUrl: `https://${hostname}` });

      const url = encodeURI(`https://${hostname}/vm-rest/responses/recaptcha`);
      const data = JSON.stringify({
        restricted_action: restrictedAction,
        user_id: this.uid,
        g_recaptcha_response: captchaResponse.data,
      });

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Cookie': this.cookie ?? '',
          'Content-Type': 'application/json',
        },
        body: data
      });

      const json = await response.json();

      if (json?.error) {
        console.log('Error verifying captcha', json?.error);
      }

      return json;
    } catch (error) {
      console.error('Error solving captcha:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }

  static hasShared(feedContext: any): boolean {
    // Post has never been shared
    if (
      feedContext === undefined ||
      feedContext === null ||
      feedContext.shared === undefined ||
      !feedContext.shared ||
      feedContext.shared_at === undefined
    ) {
      return false;
    } else {
      // Post was shared, check if the shared at date is more than 1 day
      const sharedAt = new Date(feedContext.shared_at);
      const now = new Date();
      return now.getTime() - sharedAt.getTime() < TIME.ONE_DAY;
    }
  }

  /*
 * Mark notifications as read
 */
  async markNotificationsAsRead(
    { hostname, code = NOTIFICATION_CODES.LIKES }: {
      hostname: string, code?: number
    }) {
    const url = encodeURI(`https://${hostname}/vm-rest/users/${this.uid}/last_viewed_notification/${code}`);

    try {
      const response = await fetch(url, {
        method: 'PUT'
      });

      if (!response.ok) {
        const errorMessage = `Error setting lastViewedNotifications: ${response.statusText}`;
        console.error(errorMessage);
        throw new Error(errorMessage);
      }

      return response;
    } catch (error) {
      console.error('Error setting lastViewedNotifications:', error);
      throw error;
    }
  }

  /*
   * Follow a user by id
   */
  async follow({ id, hostname }: { id: string, hostname: string }) {
    const url = encodeURI(`https://${hostname}/vm-rest/users/${id}/followers/${this.uid}`);

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Cookie': this.cookie ?? '',
        }
      });

      if (!response.ok) {
        throw new Error(`Error following user ${id}`);
      }

      const json = await response.json();
      return json;
    } catch (error) {
      console.error(`Error following user ${id}`, error);
      throw error;
    }
  }

  /*
   * Unfollow a user by id
   */
  async unfollow({ id, hostname }: { id: string, hostname: string }) {
    const url = encodeURI(`https://${hostname}/vm-rest/users/${id}/followers/${this.uid}`);

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Cookie': this.cookie ?? '',
        }
      });

      if (!response.ok) {
        throw new Error(`Error unfollowing user ${id}`);
      }

      const json = await response.json();
      return json;
    } catch (error) {
      console.error(`Error unfollowing user ${id}`, error);
      throw error;
    }
  }

  async getFollowers({
    user = this.user,
    maxId = 0,
    count = FOLLOWER_PAGE_SIZE,
    hostname
  }: {
    user?: string;
    maxId?: number;
    count?: number;
    hostname: string;
  }): Promise<any> {
    const url = encodeURI(`https://${hostname}/vm-rest/users/${user}/followers?max_id=${maxId}&count=${count}`);

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Cookie': this.cookie ?? '',
        }
      });

      if (!response.ok) {
        throw new Error('Error getting followers');
      }

      const json = await response.json();
      return json.data;
    } catch (error) {
      console.error('Error getting followers', error);
      throw error;
    }
  }

  async getFollowings({
    user = this.user,
    maxId = 0,
    count = FOLLOWER_PAGE_SIZE,
    hostname
  }: {
    user?: string;
    maxId?: number;
    count?: number;
    hostname: string;
  }): Promise<any> {
    const url = encodeURI(`https://${hostname}/vm-rest/users/${user}/following?max_id=${maxId}&count=${count}`);

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Cookie': this.cookie ?? '',
        }
      });

      if (!response.ok) {
        throw new Error('Error getting followings');
      }

      const json = await response.json();
      return json.data;
    } catch (error) {
      console.error('Error getting followers', error);
      throw error;
    }
  }

  /*
 * Gets followers using pagination
 * This function seems to have a maximum limit of 998 results! I am not sure why...
 */
  async getFollowersBatch({
    mode,
    user = this.user,
    count = BATCH_SIZE,
    hostname,
  }: {
    mode: 'followers' | 'followings',
    user: string | undefined,
    count?: number,
    hostname: string,
  }) {
    let page = 0

    const items = []

    do {
      const maxId = page * FOLLOWER_PAGE_SIZE

      let result: any;
      let filteredResult: any;

      if (mode === 'followers') {
        result = await this.getFollowers({ user, hostname, maxId })
        filteredResult = PoshAPI.filterUser({ users: result, following: false })
      } else {
        result = await this.getFollowings({ user, hostname, maxId })
        filteredResult = PoshAPI.filterUser({ users: result, following: true })
      }

      console.log(`Page: ${page}, result: ${result?.length}, filtered: ${filteredResult?.length}`)

      items.push(...filteredResult)
      page += 1

      // If the result is less than what we queried for then we have reached the end
      if (result.length < FOLLOWER_PAGE_SIZE) break
    } while (items.length < count)

    return items
  }

  // Filters out users who the caller is following or not following
  static filterUser({ users, following = true }: { users: any[], following: boolean }) {
    return users.filter((user: any) => {
      if (following) {
        return user.caller_is_following
      } else {
        return user.caller_is_following === undefined || !user.caller_is_following
      }
    })
  }
}