import React from 'react';
import { Box, Container, Divider, List, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { reselling, opinion, listing, sourcing } from '../blog';
import { Helmet } from 'react-helmet-async';
import PATHS from '../utils/paths';
import Dashboard from './dashboard/Dashboard.tsx';

const SectionList = ({ title, blogs }) => {
  const theme = useTheme();

  return (
    <Box marginBottom={2}>
      <Typography variant='h5' marginBottom={1} fontFamily={"Libre Baskerville"}>{title}</Typography>
      <Divider />
      <Box display="flex" flexDirection="row" marginTop={1}>
        <Box flex={1}>
          <List>
            {blogs.map(blog => (
              <Box marginBottom={2} key={blog.slug}>
                <Link href={`/guides/${blog.slug}`} underline="hover" style={{ color: theme.palette.text.primary }}>
                  {blog.title}
                </Link>
              </Box>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

const Guides = () => {

  const renderHeader = () => {
    return (
      <Container maxWidth={false} sx={{ pt: 4 }}>
        <Typography
          variant="h1"
          style={{
            fontWeight: "bold",
            fontSize: "1.9rem",
            fontFamily: "Libre Baskerville"
          }}>
          Guides to Help You <em>Sell and Profit</em>.
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ pt: 2, pb: 4 }}
        >
          Browse the latest Poshwatch blog posts about listing, sourcing and reselling on Poshmark to help you hit and exceed your sales goals.
        </Typography>
      </Container>
    )
  };

  return (
    <Dashboard title="Guides">
      <Helmet >
        <title>Poshmark Reselling Guides</title>
        <meta name="description" content={"Elevate your Poshmark reselling game with our expert guides. From sourcing to listing, we've got you covered."} />
        <meta property="og:title" content="Poshwatch Reseller Guides" />
        <meta property="og:description" content="All the information you need to increase your sales." />
        <meta property="og:url" content="https://poshwatch.io/guides" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`${window.location.origin}${PATHS.GUIDES}`} />
      </Helmet>
      {renderHeader()}
      <Container maxWidth={false}>
        <SectionList title="Learn Reselling" blogs={reselling} />
        <SectionList title="Listing" blogs={listing} />
        <SectionList title="Sourcing" blogs={sourcing} />
        <SectionList title="My Journey" blogs={opinion} />
      </Container>
    </Dashboard>
  );
};

export default Guides;
