import React from 'react';
import { darkTheme, lightTheme } from '../customTheme';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import FeaturePanel from '../components/FeaturePanel';
import Footer from '../components/Footer';
import NewsletterPanel from '../components/newsletter-panel/NewsletterPanel.js';
import { Helmet } from 'react-helmet-async';
import GridFeaturePanel from '../components/GridFeaturePanel';
import ToolsCard from '../components/ToolsCard.tsx';
import { Typography } from '@mui/material';

import Testimonials from '../components/Testimonials.tsx';
import Panel from '../components/Panel';
import { colors } from '../customTheme.js'
import Intercom from '@intercom/messenger-js-sdk';

// Sample data
const testimonials = [
  {
    name: "Olivia",
    title: "Posh Seller & Data Enthusiast",
    comment: "This tool is incredible! The comprehensive stats on sold listings, including average time to sell and price ranges, are invaluable for my Poshmark strategy. It's super user-friendly and provides insights I've been struggling to find elsewhere.",
    rating: 5,
  },
  {
    name: "Julia",
    title: "12 yr Thrifting Pro",
    comment: "Wow this is great! This product research tool is a game-changer for me and other Poshmark sellers. I've been using it to prepare for thrifting trips, and it's dramatically improved my ability to spot profitable items.",
    rating: 5,
  },
  {
    name: "Abbie",
    title: "8 yr Posh Veteran",
    comment: "I think i'm obsessed! I've been selling on Poshmark for years, and this tool is exactly what I've been waiting for. The ability to see detailed stats on sold listings is incredible. It's helped me price my items more effectively and understand market trends.",
    rating: 5,
  }
];

const Home = () => {
  Intercom({
    app_id: 'khe0tarm',
  });

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <Helmet>
        <title>Poshwatch - Discover The Top Selling Products on Poshmark</title>
        <meta name="description" content={"Uncover top selling brands & categories to make more sales on Poshmark. Use our reseller tools to grow your Poshmark business."} />
        <link rel="canonical" href={"https://poshwatch.io/"} />
      </Helmet>

      <div style={{ position: 'relative', zIndex: 4 }}>
        <ResponsiveAppBar style={{ position: "absolute", top: 0, left: 0, zIndex: 1000, backgroundColor: "transparent" }} />

        <div style={{
          position: 'relative',
        }}>
          <FeaturePanel
            title={
              <Typography
                fontFamily="Libre Baskerville"
                variant="h1"
                align="center"
                paddingBottom={3}
                sx={{
                  fontSize: {
                    xs: '2.5em',
                    sm: '3.3em',
                  },
                  color: 'rgba(255, 255, 255, 0.9)',
                  textShadow: `2px 2px 4px rgba(0, 0, 0, 0.2`,
                }}
              >
                Items <span style={{ color: colors.wafer }}>not </span> selling on Poshmark?
              </Typography>
            }
            subtitle={'Poshwatch helps you improve your listings and uncover the top-selling products on Poshmark.'}
            theme={darkTheme}
            backgroundColor={colors.revolverPurple}
            style={{
              position: 'relative',
              zIndex: 5,
            }}
          >
            <ToolsCard />
          </FeaturePanel>
        </div>

        <Panel theme={lightTheme}>
          <Testimonials testimonials={testimonials} />
        </Panel>

        <div style={{ position: 'relative', zIndex: 4 }}>
          <GridFeaturePanel
            theme={lightTheme}
            backgroundColor={colors.seaShell}
          />
        </div>

        <NewsletterPanel compact={false} />
        <Footer />
      </div>
    </div >
  );
};

export default Home;