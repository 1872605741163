// SearchSuggestions.tsx
import React, { useMemo } from 'react';
import { Chip, Box } from '@mui/material';

interface SearchSuggestionsProps {
  rounded?: boolean,
  count?: number,
  onSuggestionClick: (suggestion: string) => void,
}

const SearchSuggestions: React.FC<SearchSuggestionsProps> = ({
  count = 3,
  onSuggestionClick }) => {
  const suggestions = useMemo(() => {
    const suggestions = [
      { icon: "🧥", text: "Lululemon Warp Light Jacket" },
      { icon: "🦺", text: "Patagonia Nano Puff Vest" },
      { icon: "👗", text: "Free People Teegan Mini Dress" },
      { icon: "👟", text: "Nike Air Zoom Pegasus" },
      { icon: "🏃‍♂️", text: "Adidas Ultraboost Running Shoes" },
      { icon: "❄️", text: "North Face Thermoball Jacket" },
      { icon: "👕", text: "Under Armour HeatGear Compression Shirt" },
      { icon: "🧣", text: "Columbia Benton Springs Fleece" },
      { icon: "🧥", text: "Arc'teryx Atom LT Hoody" },
      { icon: "🏋️‍♂️", text: "Reebok CrossFit Nano Shoes" },
      { icon: "🏃‍♀️", text: "New Balance Fresh Foam 1080v11" },
      { icon: "🌧️", text: "Marmot PreCip Eco Jacket" },
      { icon: "🏃", text: "Hoka One One Clifton 7" },
      { icon: "⛰️", text: "Salomon Speedcross 5" },
      { icon: "🦉", text: "Mountain Hardwear Ghost Whisperer 2" },
      { icon: "👣", text: "Brooks Ghost 13 Running Shoes" },
      { icon: "👖", text: "Prana Halle Pants" },
      { icon: "🌬️", text: "Outdoor Research Ferrosi Jacket" },
      { icon: "🎒", text: "Osprey Talon 22 Backpack" }
    ];

    const shuffled = suggestions.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  }, [count]);

  return (
    <Box display="flex" flexWrap="wrap" gap={1} justifyContent="start" mt={2}>
      {suggestions.map((suggestion, index) => (
        <Chip
          variant="outlined"
          key={index}
          label={`${suggestion.icon} ${suggestion.text}`}
          onClick={() => onSuggestionClick(suggestion.text)}
          clickable
          size="medium"
          sx={{
            borderRadius: '10px',
            transition: 'transform 0.2s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />
      ))}
    </Box>
  );
};

export default SearchSuggestions;