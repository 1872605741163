import mixpanel from 'mixpanel-browser';

export interface UserProps {
  name?: string;
  email?: string;
  plan?: string;
}

class Analytics {
  private static instance: Analytics;

  private constructor() {
    // Private constructor to prevent direct construction calls with the `new` operator.
  }

  public static getInstance(): Analytics {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
      try {
        Analytics.instance.init();
      } catch (error) {
        console.error('Failed to initialize Analytics:', error);
      }
    }
    return Analytics.instance;
  }

  private init(): void {
    try {
      mixpanel.init('00dda46234a85bcda9cc33f68a8b0611', {
        debug: true,
        track_pageview: true,
        persistence: 'localStorage',
        api_host: 'https://mixpanel-tracking-proxy-a5b3xzc4dq-uc.a.run.app',
      });
    } catch (error) {
      console.error('Failed to initialize Mixpanel:', error);
    }
  }

  public identify(userId: string, props: UserProps): void {
    try {
      mixpanel.identify(userId);
      mixpanel.people.set(props);
    } catch (error) {
      console.error('Failed to identify user:', error);
    }
  }

  public reset(): void {
    try {
      mixpanel.reset();
    } catch (error) {
      console.error('Failed to reset Mixpanel:', error);
    }
  }

  public track(eventName: string, properties: any): void {
    try {
      mixpanel.track(eventName, properties);
    } catch (error) {
      console.error('Failed to track event:', error);
    }
  }
}

export const instance = Analytics.getInstance();