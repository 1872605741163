import { Box, Grid, Card, AspectRatio, Typography, Chip } from '@mui/joy';
import React from 'react';
import SectionTitle from './SectionTitle.tsx';
import { formatPrice } from '../../../utils/helpers';

const PriceTierGrid = ({ topPosts }) => {
  if (!topPosts) {
    return (
      <Box sx={{ py: 4 }}>
        <SectionTitle title="Recently sold" />
        <Card variant="soft" sx={{ p: 4, textAlign: 'center' }}>
          <Typography level="h4" sx={{ mb: 1 }}>
            No listings available
          </Typography>
        </Card>
      </Box>
    );
  }

  // Combine all posts into a single array and add tier information
  const allPosts = [
    ...(topPosts.luxury?.map(post => ({ ...post, tier: 'luxury' })) || []),
    ...(topPosts.premium?.map(post => ({ ...post, tier: 'premium' })) || []),
    ...(topPosts.midRange?.map(post => ({ ...post, tier: 'midRange' })) || []),
    ...(topPosts.budget?.map(post => ({ ...post, tier: 'budget' })) || []),
    ...(topPosts.discount?.map(post => ({ ...post, tier: 'discount' })) || [])
  ];

  if (allPosts.length === 0) {
    return (
      <Box sx={{ py: 4 }}>
        <SectionTitle title="Recently sold" />
        <Card variant="soft" sx={{ p: 4, textAlign: 'center' }}>
          <Typography level="h3" sx={{ mb: 2 }}>
            No recent sales
          </Typography>
          <Typography color="neutral">
            There haven't been any sales in our marketplace recently
          </Typography>
        </Card>
      </Box>
    );
  }

  const getTierLabel = (post) => {
    const tiers = {
      luxury: '$$$$$',
      premium: '$$$$',
      midRange: '$$$',
      budget: '$$',
      discount: '$'
    };

    return tiers[post.tier] || '';
  };

  const handleClick = (id) => {
    window.open(`https://poshmark.com/listing/${id}`, '_blank');
  };

  return (
    <Box sx={{ py: 4 }}>
      <SectionTitle title="Recently sold" />

      <Grid container spacing={1}>
        {allPosts.map((post) => (
          <Grid key={post.id} xs={6} sm={3} md={3} lg={2}>
            <Card
              variant="outlined"
              sx={{
                p: 0,
                cursor: 'pointer',
                overflow: 'hidden',
                '&:hover': {
                  boxShadow: 'md',
                  transform: 'scale(1.02)',
                  transition: 'all 0.2s'
                }
              }}
              onClick={() => handleClick(post.id)}
            >
              <Box sx={{ position: 'relative' }}>
                <AspectRatio ratio="1">
                  <img
                    src={post.picture_url}
                    alt={post.title}
                    loading="lazy"
                    style={{ 
                      objectFit: 'cover',
                      borderRadius: 'inherit'
                    }}
                  />
                </AspectRatio>
                {/* Dark overlay gradient */}
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0) 100%)',
                    height: '70%',
                    pointerEvents: 'none',
                    borderBottomLeftRadius: 'inherit',
                    borderBottomRightRadius: 'inherit'
                  }}
                />
                {/* Tier chip */}
                <Chip
                  variant="soft"
                  color="neutral"
                  size="sm"
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)'
                  }}
                >
                  {getTierLabel(post)}
                </Chip>
                {/* Title and price overlay */}
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    p: 1,
                    color: 'white',
                    borderBottomLeftRadius: 'inherit',
                    borderBottomRightRadius: 'inherit'
                  }}
                >
                  <Typography
                    level="title-sm"
                    noWrap
                    sx={{
                      color: 'white',
                      textShadow: '0 1px 2px rgba(0,0,0,0.3)',
                      mb: 0.5
                    }}
                  >
                    {post.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'white',
                      fontWeight: 'md',
                      textShadow: '0 1px 2px rgba(0,0,0,0.3)',
                    }}
                  >
                    {formatPrice(post.price)}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PriceTierGrid;