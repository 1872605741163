import React from 'react';
import { Avatar, Box, Card, CardContent, Typography, Rating, Grid, Container } from '@mui/material';
import { colors } from '../customTheme';

export interface TestimonialProps {
  name: string;
  comment: string;
  rating?: number;
  title?: string;
  avatarUrl?: string;
}

const Testimonial: React.FC<TestimonialProps> = ({
  name,
  comment,
  rating,
  title,
  avatarUrl,
}) => {
  return (
    <Card
      variant='outlined'
      sx={{
        borderRadius: '10px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Box display="flex" alignItems="center" mb={1}>
          <Avatar
            src={avatarUrl ?? name}
            alt={name}
            sx={{
              width: 48,
              height: 48,
              mr: 2,
              bgcolor: colors.revolverPurple,
            }}
          />
          <Box>
            <Typography variant="h6" component="div">
              {name}
            </Typography>
            {title && (
              <Typography variant="subtitle2" color="text.secondary">
                {title}
              </Typography>
            )}
          </Box>
        </Box>
        {rating !== undefined && (
          <Box mb={1}>
            <Rating
              value={rating}
              readOnly
              style={{
                fontSize: "30px",
                color: colors.wafer
              }} />
          </Box>
        )}
        <Typography
          variant="body1"
          color="text.secondary"
          style={{
            fontSize: "1em",
            lineHeight: 1.6
          }}
        >
          {comment}
        </Typography>
      </CardContent>
    </Card>
  );
};

interface TestimonialsProps {
  testimonials: TestimonialProps[];
}

const Testimonials: React.FC<TestimonialsProps> = ({ testimonials }) => {
  return (
    <Container>
      <Container maxWidth='md' sx={{ pb: 4 }}>
        <Typography
          fontFamily="Libre Baskerville"
          variant="h3"
          align='center'
          sx={{
            fontWeight: 'bold'
          }}
          paddingBottom={4}>Testimonials
        </Typography>
        <Typography
          variant="h4"
          align="center"
          color="textSecondary"
          style={{
            fontSize: "1.2em",
            fontWeight: 400,
            lineHeight: 1.6,
          }}
        >
          Here is what long time Poshmark resellers have to say.
        </Typography>
      </Container>
      <Grid container spacing={3}>
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Testimonial {...testimonial} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Testimonials;