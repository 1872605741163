import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Avatar } from '@mui/joy';
import { Helmet } from 'react-helmet-async';
import { formatName, formatTotalPrice, hyphenateName } from '../../utils/helpers';
import Dashboard from '../../pages/dashboard/Dashboard.tsx';
import MetricsGrid from './components/MetricsGrid.tsx';
import ProgressChart from './components/ProgressChart.tsx';
import SectionTitle from './components/SectionTitle.tsx';
import DonutChartGrid from './components/DonutChartGrid.tsx';
import SummaryTable from './components/SummaryItemTable.tsx';
import DetailsSkeleton from './components/DetailsSkeleton.tsx';
import { useDetails } from './hooks/useDetails.ts';
import { useObfuscatedDetails } from './hooks/useObfuscatedDetails.ts';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';
import PriceTierGrid from './components/PriceTierGrid.tsx';

export const DetailsV2 = () => {
  const location = useLocation();
  const { isSignedIn } = useAuth();
  const [type, setType] = useState('');
  const [id, setId] = useState('');

  useEffect(() => {
    const pathname = location.pathname;
    const parts = pathname.split('/').filter(Boolean);
    if (parts.length >= 2) {
      setType(parts[0]);
      setId(parts[1]);
    }
  }, [location]);

  const authenticatedData = useDetails({ type, id });
  const obfuscatedData = useObfuscatedDetails({ type, id });

  const {
    loading,
    byCategory,
    byBrand,
    metricsData,
    progressChartData,
    donutChartData,
    sellerPictureUrl,
    topPosts,
  } = isSignedIn ? authenticatedData : obfuscatedData;

  const renderSummaryTables = (tables, sectionTitle) => {
    return (
      <Box sx={{ mt: 4, mb: 6 }}>
        <SectionTitle title={sectionTitle} />
        <Grid container spacing={2}>
          {tables.map((table, index) => (
            <Grid key={index} xs={12} sm={6} md={4}>
              <SummaryTable
                signedIn={isSignedIn}
                title={table.title}
                items={table.items}
                columns={table.columns} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <Dashboard>
      <Helmet>
        <title>{id}</title>
        <meta name="description" content={`The latest sales data for ${formatName(id)}. Total sales, average sales per day, total items sold and more!`} />
        <meta property="og:title" content={`Top-selling ${formatName(id)} products on Poshmark`} />
        <meta property="og:description" content={`Uncover the best selling items from ${formatName(id)} on Poshmark`} />
        <meta property="og:url" content={`https://poshwatch.io/${type}/${hyphenateName(id)}`} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Container maxWidth={false}>
        <Box sx={{ my: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {type === 'seller' && sellerPictureUrl && (
                <Box
                  component="a"
                  href={`https://poshmark.com/closet/${id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: 0.8,
                    },
                    transition: 'opacity 0.2s',
                  }}
                >
                  <Avatar
                    src={sellerPictureUrl}
                    alt={id}
                    size="lg"
                  />
                </Box>
              )}
              <Box sx={{ flex: 1 }}>
                <Typography level="h2" component="h1">
                  {type === "seller" ? `Seller overview for ${id}` : `Market overview for ${formatName(id)}`}
                </Typography>
              </Box>
            </Box>
          </Box>
          {loading ? (
            <DetailsSkeleton />
          ) : (
            <>
              <MetricsGrid title={`${formatName(type)} overview`} description={(metricsData as any).description} metrics={(metricsData as any).metrics} />
              <DonutChartGrid title="Performance overview" description={(donutChartData as any).description} charts={(donutChartData as any).charts} />
              <PriceTierGrid topPosts={topPosts} />
              <Box sx={{ pt: 0 }}>
                {progressChartData && <ProgressChart
                  signedIn={isSignedIn}
                  title={progressChartData!.title}
                  description={progressChartData.description}
                  formatAmount={formatTotalPrice}
                  items={progressChartData!.data}
                />}
              </Box>
              {byCategory && renderSummaryTables(byCategory, `Sales by category`)}
              {byBrand && renderSummaryTables(byBrand, `Sales by brand`)}
            </>
          )}
        </Box>
      </Container>
    </Dashboard>
  );
};

export default DetailsV2;