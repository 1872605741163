/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../contexts/AuthContext';
import { MyFirebase } from '../../api/my_firebase.ts';
import * as StripePayment from '../../api/stripe_paypment.ts';
import LoadingButton from '@mui/lab/LoadingButton';
import Dashboard from '../dashboard/Dashboard.tsx';
import { Experimental_CssVarsProvider } from '@mui/material/styles';

import {
  Button,
  TextField,
  CardContent,
  Card,
  Typography,
  Container
} from '@mui/material';

const Settings = () => {
  const { isPremium, email } = useAuth();
  const [loading, setLoading] = useState(false);

  const app = MyFirebase.getInstance().getApp();

  return (
    <Dashboard>
      <Helmet >
        <title>Settings</title>
        <meta name="description" content={"Account Settings"} />
      </Helmet>
      <Experimental_CssVarsProvider>
        <Container maxWidth={false} sx={{ pt: 4 }}>
          <Typography
            variant="h1"
            style={{
              fontSize: "1.8em",
              fontWeight: 'bold',
              fontFamily: "Libre Baskerville"
            }}
            sx={{ pb: 2 }}
          >
            Settings
          </Typography>
          <Card variant="outlined" style={{ minWidth: "400px" }}>
            <CardContent style={{
              padding: "30px",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start'
            }}>
              {email && <TextField
                sx={{ mt: 0, }}
                size="small"
                label="Email"
                variant="outlined"
                disabled
                value={email}
                style={{ marginBottom: "16px" }}
              />}
              {
                isPremium &&
                <LoadingButton variant="outlined" color="inherit"
                  style={{
                    backgroundColor: '#f2f2f2',
                    textTransform: 'none',
                    marginBottom: "16px",
                    height: "40px",
                  }}
                  loading={loading}
                  onClick={async () => {
                    setLoading(true);
                    const url = await StripePayment.getPortalUrl(app)
                    window.open(url, '_blank');
                    setLoading(false);
                  }}
                >
                  Manage Membership
                </LoadingButton>
              }
              <Button
                variant="outlined"
                color="inherit"
                style={{
                  textTransform: 'none',
                  height: "40px"
                }}
                onClick={() => {
                  MyFirebase.getInstance().signOut();
                }}
              >
                Logout
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Experimental_CssVarsProvider>
    </Dashboard>
  );
};

export default Settings;
