import { styled, Button } from '@mui/material';

const PrimaryButton = styled(Button)({
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  borderRadius: '10px',
  height: '48px',
  textTransform: 'none',
  fontSize: '18px',
  paddingLeft: '24px',
  paddingRight: '24px',
  textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)',
  color: 'white'
});

export default PrimaryButton;