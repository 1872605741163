// src/App.js
import React, { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/sign-in/SignIn";
import Register from "./pages/register/Register";
import Premium from "./pages/premium/Premium";
import NotFound from "./pages/NotFound";
import PremiumStatus from "./pages/premium/PremiumStatus";
import FeeCalculator from "./pages/FeeCalculator";
import Assistant from "./pages/assistant/Assistant";
import Settings from "./pages/settings/Settings";
import ListingAssistant from "./pages/listing-assistant/ListingAssistant";
import ClosetReport from "./pages/closet-report/ClosetReport";
import ListingRankChecker from "./pages/ListingRankChecker";
import TopBrands from "./pages/TopBrands";
import TopSellers from "./pages/TopSellers";
import Blogs from "./pages/Guides";
import { all } from "./blog"
import MarkdownBlog from "./components/MarkdownBlog";
import { DetailsV2 } from "./pages/details/DetailsV2.tsx";
import { PoshmarkAnalyzer } from "./pages/analyzers/poshmark";
import { DepopAnalyzer } from "./pages/analyzers/depop";
import { MercariAnalyzer } from "./pages/analyzers/mercari";
import { EbayAnalyzer } from "./pages/analyzers/ebay";
import { GrailedAnalyzer } from "./pages/analyzers/grailed";
import StlyeGlossary from "./pages/StyleGlossary";
import PATHS from "./utils/paths";
import TopCategories from "./pages/TopCategories";
import { useAuth } from './contexts/AuthContext';
import ScrollToTop from "./components/ScrollToTop";
import { Search } from "./pages/search/Search";
import CompareListings from "./pages/CompareListings";
import PostGenerator from "./pages/post-generator/PostGenerator.tsx";
import { UnsubscribePage } from "./pages/Unsubscribe.js";

function App() {
  useEffect(() => {
    const options = {
      autoConfig: true,  // set to false if you have events you do not want to track automatically
      debug: false,      // enable logs for debugging
    };
    ReactPixel.init("725295266074177", null, options);
    ReactPixel.pageView(); // For tracking page view
  }, []);

  const { isSignedIn, isPremium, isAdmin } = useAuth();

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path={PATHS.HOME} element={<Home />} />
          <Route
            path={PATHS.SIGN_IN}
            element={
              isSignedIn ? <Navigate to={PATHS.HOME} /> : <SignIn />
            }
          />
          <Route
            path={PATHS.REGISTER}
            element={
              isSignedIn ? <Navigate to={PATHS.HOME} /> : <Register />
            }
          />
          <Route
            path={PATHS.PREMIUM}
            element={
              isPremium === true ? <Navigate to={PATHS.HOME} /> : <Premium />
            }
          />
          <Route
            path={PATHS.SETTINGS}
            element={
              isSignedIn === false ? <Navigate to={PATHS.SIGN_IN} /> : <Settings />
            }
          />
          <Route path={PATHS.PREMIUM_UNLOCKED} element={<PremiumStatus />} />
          <Route path={PATHS.GUIDES} element={<Blogs />} />
          <Route path={PATHS.FEE_CALCULATOR} element={<FeeCalculator />} />
          <Route path={PATHS.POST_GENERATOR} element={<PostGenerator />} />
          <Route path={PATHS.UNSUBSCRIBE} element={<UnsubscribePage />} />
          <Route path={PATHS.POSHMARK_ASSISTANT} element={<Assistant
            title={"Closet Sharer"}
            description={"Closet Sharer"} subtitle={"Automatically share your closet and make more sales"} />} />
          <Route path={PATHS.CLOSET_SHARER} element={<Navigate to={PATHS.POSHMARK_ASSISTANT} replace />} />

          <Route path={PATHS.LISTING_ASSISTANT} element={<ListingAssistant
            title={"Listing Assistant"}
            description={"Listing Assistant"} subtitle={"List faster"} />} />
          <Route
              path={PATHS.COMPARE_LISTINGS}
              element={
                <CompareListings
                  title={"Compare Listings"}
                  description={"Compare your Poshmark listings to bestsellers, gain insights to improve your listings and increase sales"}
                  subtitle={"Compare your Poshmark listings to bestsellers, to improve your listings, rank higher and sell more"}
                />
              }
            />
          <Route
            path={PATHS.CLOSET_REPORT}
            element={
              isSignedIn === false ? (
                <Navigate to={PATHS.SIGN_IN} />
              ) : !isAdmin ? (
                <Navigate to={PATHS.HOME} />
              ) : (
                <ClosetReport
                  title={"Closet Report"}
                  description={"Closet Report - Get insights on your closet to improve the rank of your listings"}
                  subtitle={"Get the feedback you need to improve the ranking of your listings and make more sales"}
                />
              )
            }
          />
          <Route
            path={PATHS.RANK_CHECKER}
            element={
              <ListingRankChecker
                title={"Listing Rank Check"}
                description={"See how well your Poshmark listing ranks for relevant keywords and uncover insights to increase your listing's visibility in search results. Fine-tune your titles, descriptions, and tags to rank higher, attact more buyers and stand out in a crowded marketplace."}
                subtitle={"See how well your listing ranks for keywords and uncover insights to increase your listing's visibility"}
              />
            }
          />
          <Route path={PATHS.PRODUCT_RESEARCH} element={<Search />} />

          <Route path={PATHS.TOP_BRANDS} element={<TopBrands />} />
          <Route path={PATHS.TOP_BRANDS_SHORT} element={<Navigate to={PATHS.TOP_BRANDS} replace />} />

          <Route path={PATHS.TOP_SELLERS} element={<TopSellers />} />
          <Route path={PATHS.TOP_SELLERS_SHORT} element={<Navigate to={PATHS.TOP_SELLERS} replace />} />

          <Route path={PATHS.TOP_CATEGORIES} element={<TopCategories />} />
          <Route path={PATHS.TOP_CATEGORIES_SHORT} element={<Navigate to={PATHS.TOP_CATEGORIES} replace />} />

          <Route path={PATHS.LISTING_ANALYZER} element={<PoshmarkAnalyzer />} />
          <Route path={PATHS.POSHMARK_LISTING_ANALYZER} element={<PoshmarkAnalyzer />} />
          <Route path={PATHS.DEPOP_LISTING_ANALYZER} element={<DepopAnalyzer />} />
          <Route path={PATHS.MERCARI_LISTING_ANALYZER} element={<MercariAnalyzer />} />
          <Route path={PATHS.EBAY_LISTING_ANALYZER} element={<EbayAnalyzer />} />
          <Route path={PATHS.GRAILED_LISTING_ANALYZER} element={<GrailedAnalyzer />} />
          <Route path={PATHS.STYLE_GLOSSARY} element={<StlyeGlossary />} />
          <Route path={PATHS.SELLERS} element={<DetailsV2 />} />
          <Route path={PATHS.BRAND} element={<DetailsV2 />} />
          <Route path={PATHS.CATEGORY} element={<DetailsV2 />} />
          {all.map((blog, index) => {
            const path = `${PATHS.GUIDES}/${blog.slug}`;
            return <Route key={index} path={`${PATHS.GUIDES}/${blog.slug}`} element={<MarkdownBlog title={blog.title} description={blog.description} path={path} markdown={blog.markdown} />} />
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>

  );
}

export default App;