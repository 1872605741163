import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ResponsiveAppBar } from '../../components/ResponsiveAppBar.js';
import Footer from '../../components/Footer.js';
import { Helmet } from 'react-helmet-async';
import {
  Card,
  CardContent,
  Container,
  Box,
  Divider,
  Typography,
  Button
} from '@mui/material';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import LoadingButton from '@mui/lab/LoadingButton';
import Panel from '../../components/Panel.js';
import { lightTheme } from '../../customTheme.js';
import Check from '@mui/icons-material/Check';
import * as StripePayment from '../../api/stripe_paypment.ts';
import { MyFirebase } from '../../api/my_firebase.ts';
import { useAuth } from '../../contexts/AuthContext';
import SignInCard from '../../components/SignInCard.js';
import { colors } from '../../customTheme.js';
import PATHS from '../../utils/paths';

const FeatureItem = ({ children }) => (
  <div style={{ display: 'flex', alignItems: 'start', paddingTop: "0px", paddingBottom: "16px" }}>
    <Check style={{ marginRight: 10, marginTop: 1, color: colors.green, fontWeight: "bold" }} />
    <Typography variant="body1" component="span" style={{ fontSize: "1em" }}>{children}</Typography>
  </div>
);

const PremiumCard = ({ isSignedIn, loading, handleCheckout }) => {
  return (
    <div>
      <Typography variant="h4" fontFamily={"Libre Baskerville"}>
        Premium
      </Typography>
      <Typography variant="body1" paddingTop={2} paddingBottom={4} >
        Unlock exclusive Premium membership benefits:
      </Typography>

      <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <FeatureItem>Access top 200 brands and sellers</FeatureItem>
        <FeatureItem>Browse all top categories</FeatureItem>
        <FeatureItem>Explore detailed seller history & advanced stats</FeatureItem>
        <FeatureItem>Unlock category insights: fast-selling, popular sizes, departments</FeatureItem>
        <FeatureItem>Get early access to beta features</FeatureItem>
      </div>

      {isSignedIn && <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <LoadingButton loading={loading} onClick={handleCheckout} variant="contained" color="primary" fullWidth
          startIcon={<WorkspacePremiumIcon />} style={{
            textTransform: 'none', height: "40px"
          }}>
          Unlock Premium
        </LoadingButton>
      </div>}
    </div>
  );
}

const Premium = () => {
  const { isSignedIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleCheckout = async () => {
    setLoading(true);

    const app = MyFirebase.getInstance().getApp();
    const url = await StripePayment.getCheckoutUrl(
      app,
      StripePayment.PRICE_ID,
    );

    // Can't use react-router-dom navigate here because its going to an external url
    window.location.href = url;
  }

  return (
    <div style={{ position: 'relative' }}>
      <Helmet >
        <title>Buy Premium</title>
        <meta name="description" content={""} />
      </Helmet>
      <ResponsiveAppBar hideLinks />
      <Panel theme={lightTheme}>
        <Container maxWidth='sm' style={{ display: 'flex', justifyContent: 'center' }}>
          <Card variant="outlined" style={{ minWidth: "400px" }}>
            <CardContent style={{ padding: "30px" }}>
              <PremiumCard isSignedIn={isSignedIn} loading={loading} handleCheckout={handleCheckout} />
              {!isSignedIn &&
                <SignInCard register={true} onSignIn={async () => {
                  await handleCheckout();
                }} />}

              <Box paddingBottom={2} paddingTop={2}>
                <Divider />
              </Box>
              <Button
                onClick={
                  () => {
                    navigate(PATHS.SIGN_IN);
                  }
                }
                variant="text"
                color="primary"
                fullWidth
                style={{ textTransform: 'none', textDecoration: 'underline' }}
              >
                Already have an account? Sign In
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Panel>
      <Footer />
    </div >
  );
};

export default Premium;