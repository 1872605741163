import React from 'react';
import { Button, Card, CardContent, Container, Box, Grid, Typography, useMediaQuery } from '@mui/material';
import Panel from './Panel';
import { colors } from '../customTheme';
import ListingScore from '../images/home/listing_score.png';
import OffersToLikers from '../images/home/offers_to_likers.png';
import ClosetSharing from '../images/home/sharing_tool.png';
import ShareSchedule from '../images/home/share_schedule.png';
import { useNavigate } from 'react-router-dom';
import PATHS from '../utils/paths';
import HeroButton from './HeroButton.tsx';

const Item = ({ title, description, children, onClick, ctaLabel }) => {
  return (
    <Card variant="contained" style={{
      textAlign: 'left',
      height: '100%',
      backgroundColor: colors.wildSand,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: 10,
      border: `1px dashed ${colors.wafer}`,
    }}>
      <CardContent
        sx={{
          padding: {
            xs: 2,
            sm: 4,
          },
          display: 'flex',
          flexDirection: 'column',
        }}>
        {children}
        <div>
          <Typography variant="h4"
            fontFamily="Libre Baskerville"
            style={{
              paddingTop: 20,
              fontSize: 22,
              fontWeight: 'bold',
              paddingBottom: 10
            }}>
            {title}
          </Typography>
          <Typography variant="body1" color="textSecondary" style={{ fontSize: 16 }}>
            {description}
          </Typography>
        </div>
        {onClick && <Button
          onClick={onClick}
          variant="outlined"
          style={{
            alignSelf: 'flex-start',
            marginTop: 12,
            textTransform: 'none'
          }}>{ctaLabel ?? "Explore"}</Button>}
      </CardContent>
    </Card>
  );
};

const GridFeaturePanel = ({
  theme,
  backgroundColor,
  mt = 0,
  mb = 0,
}) => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  return (
    <Panel theme={theme} backgroundColor={backgroundColor}>
      <Box
        sx={{
          marginTop: { xs: mt / 4, md: mt },
          marginBottom: { xs: mb / 4, md: mb },
        }}
      >
        <Container maxWidth='md' align='center' sx={{ pb: 4 }}>
          <Typography
            fontFamily="Libre Baskerville"
            variant="h3"
            align='center'
            sx={{
              fontWeight: 'bold'
            }}
            paddingBottom={4}>All the tools you need to save time and <span style={{ fontStyle: 'italic' }}>make more sales</span>.
          </Typography>
          <Box sx={{ pb: 5, pt: 0 }}>
            <HeroButton backgroundColor={colors.blackCurrent} textColor='white'
              onClick={() => navigate(
                PATHS.REGISTER)}
            >
              Sign up for free
            </HeroButton>
          </Box>
          <Typography
            variant="h4"
            align="center"
            color="textSecondary"
            style={{
              fontSize: "1.2em",
              fontWeight: 400,
              lineHeight: 1.6,
            }}
          >
            Find the best-selling items for your inventory with our Product Research tool. Automate sharing and offers with our Closet Sharer. Fix listing issues with our Closet Analyzer, so your items rank higher on Poshmark and make more sales.
          </Typography>
        </Container>
        <Container maxWidth='lg' align='center'>
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} md={8}>
              <Item
                title={"Closet Sharing Tool"}
                description={
                  "Share your closet with ease followers to boost your listing rankings. Increase visibility and engagement with automated sharing, ensuring your items are seen by more potential buyers."
                }
                ctaLabel={"Explore"}
                onClick={() => {
                  navigate(PATHS.POSHMARK_ASSISTANT);
                }}
              >
                <img
                  alt="Closet sharing tool"
                  src={ClosetSharing}
                  style={!isSm ? {
                    alignSelf: "start",
                    maxHeight: "120px",
                    objectFit: "contain"
                  } : {}}
                />
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item
                title={"Make Offers to Likers"}
                description={
                  "Automatically send offers to users who have liked your items, increasing your chances of making a sale. Set custom discounts and track the effectiveness of your offers."
                }
              >
                <img
                  alt="Offers to Likers"
                  src={OffersToLikers}
                  style={{
                    alignSelf: "start",
                    maxHeight: "100px",
                    objectFit: "contain"
                  }}
                />
              </Item>
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12} md={4}>
                <Item
                  title={"Listing Analyzer"}
                  description={
                    "Get detailed feedback on photos, titles, descriptions, and more to improve your listings and attract more buyers."
                  }
                  ctaLabel={"Explore"}
                  onClick={() => {
                    navigate(PATHS.POSHMARK_LISTING_ANALYZER);
                  }}
                >
                  <img
                    alt="Listing Analyzer"
                    src={ListingScore}
                    style={!isSm ? {
                      alignSelf: "start",
                      maxWidth: "260px",
                      objectFit: "contain"
                    } : {}}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} md={8}>
                <Item
                  title={"Share Schedule"}
                  description={
                    "Reclaim your time by setting your closet shares on autopilot. Customize your sharing schedule to match peak activity times, ensuring maximum exposure without the need for constant manual effort."
                  }
                >
                  <img
                    alt="Share schedule"
                    src={ShareSchedule}
                    style={{
                      alignSelf: "start",
                      maxHeight: "100px",
                      objectFit: "contain"
                    }}
                  />
                </Item>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Item
                title={"Sales Insights"}
                description={
                  "Leverage data from millions of listings to gain a competitive edge. Access in-depth analytics on trending items, pricing strategies, and successful listing practices to stay ahead of the market on Poshmark."}
                ctaLabel={"Learn More"}
                onClick={() => {
                  navigate(PATHS.TOP_CATEGORIES);
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Panel>
  );
};

export default GridFeaturePanel;
