//
// Design Goals:
// A task is self contained and has a clear interface
// A task can only stop itself
// You can have multiple tasks running at the same time
// Tasks should not interfere with each other's operations
//

import { v4 as uuid } from 'uuid';
import { TaskStatus } from './constants'
import PoshAPI from '../poshmark/api';
import { ACTIONS, RESTRICTED_ACTIONS, POSH_ERRORS } from '../poshmark/constants';
import { PostMin, Log, User } from '../types'

//
// Number of actions to perform for each call to doWork
//
const ACTIONS_PER_CYCLE = 10

//
// Amount of time to wait between each action
//
const DELAY_BETWEEN_ACTIONS_MS = 1500

const getSleepTime = (min: number = 30, max: number = 60) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return (Math.floor(Math.random() * (max - min + 1)) + min) * 1000;
}

export default class Task {
  id: string;
  name: string;
  status: TaskStatus;
  action: string;
  items: PostMin[] | User[];
  hostname: string;
  username: string;
  actionsCounter: number;
  lastExecuted: Date;
  onStatusChanged?: (status: string, log?: Log) => void;
  onExecute?: ({ id, log }: { id: string, log: Log }) => void;

  private timeoutId: NodeJS.Timeout | null = null;

  constructor({
    id = uuid(),
    name = "New Task",
    status = TaskStatus.PAUSED,
    action,
    items = [],
    hostname,
    username,
    actionsCounter = 0,
    lastExecuted = new Date(),
    onStatusChanged,
    onExecute,
  }: {
    id?: string
    name?: string,
    status?: TaskStatus,
    action: string,
    items: PostMin[] | User[],
    hostname: string,
    username: string,
    actionsCounter?: number,
    lastExecuted?: Date,
    onStatusChanged?: (status: string, log?: Log) => void,
    onExecute?: (activity: any) => void
  }) {
    this.id = id
    this.name = name
    this.status = status
    this.action = action
    this.items = items
    this.hostname = hostname
    this.username = username
    this.actionsCounter = actionsCounter
    this.lastExecuted = lastExecuted
    this.onStatusChanged = onStatusChanged
    this.onExecute = onExecute
  }

  static fromMap({ map }: { map: any }) {
    const task = new Task({
      id: map.id,
      name: map.name,
      status: map.status,
      action: map.action,
      items: map.items,
      hostname: map.hostname,
      username: map.username,
      lastExecuted: map.lastExecuted
    })
    return task
  }

  toMap() {
    return {
      id: this.id,
      name: this.name,
      status: this.status,
      action: this.action,
      items: this.items,
      hostname: this.hostname,
      username: this.username,
      lastExecuted: this.lastExecuted
    }
  }

  //
  // The task should start executing its job
  // It should schedule the next timeout
  // It should set the status to active
  //
  async start() {
    this.status = TaskStatus.ACTIVE
    if (this.onStatusChanged) this.onStatusChanged(this.status);
    await this.doWork()
  }

  async stop(status = TaskStatus.PAUSED) {
    console.log("Stopping task")
    this.status = status
    this.actionsCounter = 0

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }

    if (this.onStatusChanged) this.onStatusChanged(this.status);
  }

  async sleep() {
    const sleepMs = getSleepTime(10, 20);

    const message = `Sleeping (${sleepMs / 1000}s) - Remaining: ${this.items.length}`;

    console.log(message)
    this.status = TaskStatus.SLEEP;

    this.actionsCounter = 0
    this.timeoutId = setTimeout(() => this.start(), sleepMs)

    const log: Log = {
      message,
      date: new Date().toISOString()
    }

    if (this.onStatusChanged) this.onStatusChanged(this.status, log);
  }

  async doWork() {
    if (this.status === TaskStatus.COMPLETE) {
      return;
    }
    else if (this.status === TaskStatus.PAUSED) {
      console.log('Task was paused/stopped');
      return;
    }
    else if (this.items.length <= 0) {
      await this.stop(TaskStatus.COMPLETE)
      return;
    }

    await this.execute()

    if (this.items.length <= 0) {
      await this.stop(TaskStatus.COMPLETE)
    } else if (this.actionsCounter < ACTIONS_PER_CYCLE) {
      this.timeoutId = setTimeout(() => this.doWork(), DELAY_BETWEEN_ACTIONS_MS)
    } else {
      this.sleep();
    }
  }

  async execute() {
    // console.log('executing')
    this.actionsCounter += 1
    this.lastExecuted = new Date()

    const item = this.items.shift()
    const hostname = this.hostname
    const action = this.action
    const dryRun = false

    const log: Log = {
      data: {
        id: item?.id
      },
      date: new Date().toISOString(),
      type: this.action,
    }

    if (!dryRun) {
      // TODO: Need to support more actions
      if (action === ACTIONS.SHARE && item) {
        const response = await PoshAPI.getInstance().share({ id: item.id, hostname })

        if (response?.error) {
          log.message = `Error sharing ${item.id}`;
          log.error = response?.error?.errorType;
          await this.handleCaptcha({ error: response?.error, hostname, restrictedAction: RESTRICTED_ACTIONS.SHARE_POST })
        }
        else {
          log.message = `Shared ${item.id}`;
        }
      } else if (action === ACTIONS.FOLLOW && item) {
        const response = await PoshAPI.getInstance().follow({ id: item.id, hostname })

        if (response?.error) {
          log.message = `Error following ${item.id}`;
          log.error = response?.error?.errorType;
          await this.handleCaptcha({ error: response?.error, hostname, restrictedAction: RESTRICTED_ACTIONS.FOLLOW_PERSON })
        }
        else {
          log.message = `Followed ${item.id}`;
        }
      } else if (action === ACTIONS.UNFOLLOW && item) {
        const response = await PoshAPI.getInstance().unfollow({ id: item.id, hostname })

        if (response?.error) {
          log.message = `Error unfollowing ${item.id}`;
          log.error = response?.error?.errorType;
          await this.handleCaptcha({ error: response?.error, hostname, restrictedAction: RESTRICTED_ACTIONS.UNFOLLOW_PERSON })
        }
        else {
          log.message = `Unfollowed ${item.id}`;
        }
      }
      else {
        console.log('Action is unknown', action)
      }
    }

    if (this.onExecute) {
      this.onExecute({ id: item!.id, log });
    }
  }

  async handleCaptcha({ error, hostname, restrictedAction }: { error: any, hostname: string, restrictedAction: string }) {
    if (error !== undefined && error.errorType.includes(POSH_ERRORS.SUSPECTED_BOT_ERROR)) {
      await PoshAPI.getInstance().solveCaptcha({ hostname, restrictedAction })
    }
  }

  static getSuccessMessage = (action: string) => {
    switch (action) {
      case ACTIONS.LIKE:
        return { type: 'success', message: 'Liking listings' }
      case ACTIONS.FOLLOW:
        return { type: 'success', message: 'Following users' }
      case ACTIONS.UNFOLLOW:
        return { type: 'success', message: 'Unfollowing users' }
      case ACTIONS.SHARE:
        return { type: 'success', message: 'Sharing to your followers' }
      case ACTIONS.SHARE_PARTY:
        return { type: 'success', message: 'Sharing to party' }
      default:
        return { type: 'success', message: 'Task started' }
    }
  }

  static getErrorMessage = (action: string) => {
    switch (action) {
      case ACTIONS.LIKE:
        return { type: 'error', message: 'No listings to like on this page' }
      case ACTIONS.FOLLOW:
        return { type: 'error', message: 'No users to follow on this page' }
      case ACTIONS.UNFOLLOW:
        return { type: 'error', message: 'No users to unfollow on this page' }
      case ACTIONS.SHARE:
      case ACTIONS.SHARE_PARTY:
        return { type: 'error', message: 'No listings to share on this page' }
      default:
        return { type: 'error', message: 'Cannot perform task on this page' }
    }
  }
}
