import { useState, useEffect, useMemo } from 'react';
import { getDetails } from '../../../api/client';
import { formatTotalPrice, formatName, formatNumberStandard } from '../../../utils/helpers';

const TOP_N = 10;

export const useDetails = ({ type, id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type && id) {
      setLoading(true);

      const fetchData = async () => {
        try {
          let adjustedType = type;
          if (type === 'seller') adjustedType = 'creators';
          else if (type === 'brand') adjustedType = 'brands';
          else if (type === 'category') adjustedType = 'categories';

          const result = await getDetails({ type: adjustedType, id });
          setData(result);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [id, type]);

  const sellerPictureUrl = useMemo(() => {
    if (!data || type !== 'seller') return null;

    const seller = (data as any).byCreator.mostSales
      .find(seller => seller.name === id)?.seller;

    return seller?.picture_url;
  }, [data, type, id]);

  const byCategory = useMemo(() => {
    if (!data) return null;

    const categoryData = (data as any).byCategory;

    const dataFormatted = [
      {
        title: "Most Items Sold",
        items: categoryData.mostItems.slice(0, TOP_N),
        columns: [
          { key: 'name', label: 'Category', format: formatName },
          { key: 'items', label: 'Items Sold', format: formatNumberStandard }
        ]
      },
      {
        title: "Most Sales",
        items: categoryData.mostSales.slice(0, TOP_N),
        columns: [
          { key: 'name', label: 'Category', format: formatName },
          { key: 'amount', label: 'Total Sales', format: formatTotalPrice }
        ]
      },
      {
        title: "Highest Average Prices",
        items: categoryData.highestAveragePrices.slice(0, TOP_N),
        columns: [
          { key: 'name', label: 'Category', format: formatName },
          { key: 'average', label: 'Average Price', format: formatTotalPrice }
        ]
      },
      {
        title: "Most Popular Sizes",
        items: categoryData.mostPopularSizes.slice(0, TOP_N),
        columns: [
          { key: 'size', label: 'Size', format: formatName },
          { key: 'count', label: 'Count', format: formatNumberStandard }
        ]
      }
    ];

    return dataFormatted;
  }, [data]);

  const byBrand = useMemo(() => {
    if (!data) return null;

    const brandData = (data as any).byBrand;

    if (type === 'brand') {
      return null;
    }

    const dataFormatted = [
      {
        title: "Most Items Sold",
        items: brandData.mostItems.slice(0, TOP_N),
        columns: [
          { key: 'name', label: 'Brand', format: formatName },
          { key: 'items', label: 'Items Sold', format: formatNumberStandard }
        ]
      },
      {
        title: "Most Sales",
        items: brandData.mostSales.slice(0, TOP_N),
        columns: [
          { key: 'name', label: 'Brand', format: formatName },
          { key: 'amount', label: 'Total Sales', format: formatTotalPrice }
        ]
      },
      {
        title: "Highest Average Prices",
        items: brandData.highestAveragePrices.slice(0, TOP_N),
        columns: [
          { key: 'name', label: 'Category', format: formatName },
          { key: 'average', label: 'Average Price', format: formatTotalPrice }
        ]
      },
      {
        title: "Most Popular Sizes",
        items: brandData.mostPopularSizes.slice(0, TOP_N),
        columns: [
          { key: 'size', label: 'Size', format: formatName },
          { key: 'count', label: 'Count', format: formatNumberStandard }
        ]
      }
    ];

    return dataFormatted;
  }, [data, type]);

  const metricsData = useMemo(() => {
    if (!data) return [];

    const overview = (data as any).overview;

    const metrics = [
      {
        title: "Total Sales",
        subtitle: "last month",
        amount: formatTotalPrice(overview.totalSales)
      },
      {
        title: "Total Sold Listings",
        subtitle: "last month",
        amount: formatNumberStandard(overview.totalListings)
      },
    ];

    if (type === 'brand' || type === 'category') {
      metrics.push({
        title: "Number of Sellers",
        subtitle: "last month",
        amount: formatNumberStandard(overview.totalUniqueSellers)
      })
    }

    const result = {
      description: overview?.brandOverview,
      metrics,
    }

    return result;
  }, [data, type]);

  const progressChartData = useMemo(() => {
    if (!data) return null;

    const overview = (data as any).overview;

    if (type === 'brand' || type === 'category') {
      return {
        description: overview.resellerInsights,
        title: `Top resellers for ${formatName(id)}`,
        data: (data as any).byCreator.mostSales.slice(0, TOP_N).map(seller => ({
          name: seller.name,
          amount: seller.amount,
          href: `/seller/${seller.name}`,
          picture_url: seller?.seller?.picture_url
        }))
      };
    }
    else if (type === 'seller') {
      return {
        title: 'Top categories',
        data: (data as any).byCategory.mostSales.slice(0, TOP_N).map(category => ({
          name: (category.name),
          amount: category.amount,
        }))
      };
    }

    return null
  }, [data, id, type]);

  const topPosts = useMemo(() => {
    if (!data) return null;

    const overview = (data as any).overview;
    const { listingsByPriceTier } = overview;

    return {
      luxury: listingsByPriceTier.luxury.topPosts,
      premium: listingsByPriceTier.premium.topPosts,
      midRange: listingsByPriceTier.midRange.topPosts,
      budget: listingsByPriceTier.budget.topPosts,
      discount: listingsByPriceTier.discount.topPosts
    };
  }, [data]);

  const donutChartData = useMemo(() => {
    if (!data) return [];

    const overview = (data as any).overview;

    const charts = [
      {
        title: "Time to Sell",
        centerLabel: "Sale Time",
        data: [
          { name: '< 24 hours', value: overview.timeToSell.lessThan24Hours },
          { name: '1-3 days', value: overview.timeToSell.oneToThreeDays },
          { name: '4-7 days', value: overview.timeToSell.fourToSevenDays },
          { name: '1-2 weeks', value: overview.timeToSell.oneToTwoWeeks },
          { name: '2-4 weeks', value: overview.timeToSell.twoToFourWeeks },
          { name: '> 1 month', value: overview.timeToSell.moreThanOneMonth },
        ]
      },
      {
        title: "Listings by Price",
        centerLabel: "Price Tiers",
        data: [
          { name: 'Luxury', value: overview.listingsByPriceTier.luxury.count },
          { name: 'Premium', value: overview.listingsByPriceTier.premium.count },
          { name: 'Mid-range', value: overview.listingsByPriceTier.midRange.count },
          { name: 'Budget', value: overview.listingsByPriceTier.budget.count },
          { name: 'Discount', value: overview.listingsByPriceTier.discount.count },
        ]
      },
      {
        title: "Listings by Condition",
        centerLabel: "Condition",
        data: Object.entries(overview.listingsByCondition).map(([name, value]) => ({
          name: formatName(name), value: typeof value === 'number' ? value : 0
        }))
      },
    ];

    const results = {
      description: overview.salesPerformance,
      charts,
    }

    return results;
  }, [data]);

  return {
    type,
    id,
    loading,
    sellerPictureUrl,
    byCategory,
    byBrand,
    metricsData,
    progressChartData,
    topPosts,
    donutChartData
  };
};