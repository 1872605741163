/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Card, Container, Typography, TextField, CardContent, InputAdornment } from '@mui/material';
import useFeeCalculator from '../hooks/useFeeCalculator';
import { Helmet } from 'react-helmet-async';
import FeeCalculatorDetails from '../components/FeeCalculatorDetails';
import PATHS from '../utils/paths';
import Dashboard from './dashboard/Dashboard.tsx';
import { Experimental_CssVarsProvider } from '@mui/material/styles';

const canonicalUrl = `${window.location.origin}${PATHS.FEE_CALCULATOR}`;

const FeeCalculator = () => {
  const {
    emptyState,
    sellerFee,
    cost,
    setCost,
    salePrice,
    setSalePrice,
    grossProfit,
    profitMargin,
  } = useFeeCalculator();

  return (
    <Dashboard>
      <Helmet >
        <title>Poshmark Fee Calculator - Calculate Your Profits Instantly</title>
        <meta name="description" content={"Determine your Poshmark fees and potential profits using our user-friendly and completely free Poshmark fee calculator."} />
        <meta property="og:title" content="Poshmark Fee calculator" />
        <meta property="og:description" content="Calculate Your Profits Instantly" />
        <meta property="og:url" content="https://poshwatch.io/poshmark-fee-calculator" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Experimental_CssVarsProvider>
        <Container sx={{ pl: 2, pr: 2, pt: 4 }}>
          <Typography variant="h1" style={{
            fontSize: "1.8em",
            fontWeight: 'bold',
            fontFamily: "Libre Baskerville"
          }}
            sx={{ pb: 2 }}
          >
            Poshmark Fee Calculator
          </Typography>
          <Card variant='outlined'>
            <CardContent sx={{ p: 4 }}>
              <Typography>Enter your sale price and cost:</Typography>
              <TextField
                size="small"
                label="Item Sale Price"
                variant="outlined"
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (!isNaN(value)) {
                    setSalePrice(value);
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
              />
              <TextField
                size="small"
                label="Item Cost"
                variant="outlined"
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (!isNaN(value)) {
                    setCost(value);
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
              />
            </CardContent>
          </Card>
          <Card variant='outlined' sx={{ mt: 2 }}>
            <CardContent sx={{ pb: 4, pr: 4, pl: 4, pt: 4 }}>
              <Typography style={{ paddingBottom: '8px' }}>
                Seller Fee: ${sellerFee.toFixed(2)}
              </Typography>
              <Typography style={{ paddingBottom: '8px' }}>
                Cost of Goods Sold: ${cost.toFixed(2)}
              </Typography>
              <Typography style={{ paddingBottom: '8px' }}>
                Revenue: ${salePrice.toFixed(2)}
              </Typography>
              <Typography style={{ paddingBottom: '8px' }}>
                Gross Profit: {emptyState ? 'N/A' : (grossProfit < 0 ? '-' : '') + '$' + Math.abs(grossProfit).toFixed(2)}
              </Typography>
              <Typography style={{ paddingBottom: '8px' }}>
                Profit Margin: {emptyState ? 'N/A' : profitMargin.toFixed(2) + '%'}
              </Typography>
            </CardContent>
          </Card>
        </Container>
        <FeeCalculatorDetails />
      </Experimental_CssVarsProvider>
    </Dashboard >
  );
};

export default FeeCalculator;